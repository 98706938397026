import React, { Fragment, useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus, faSearch, faInfoCircle, faArrowRight, faArrowDown, faArrowUp, faCog, faThumbtack, faCheckSquare, faTrash, faEdit, faSave, faUndoAlt, faDownload, faUpload, faFileCsv } from '@fortawesome/free-solid-svg-icons';
import Badge from 'react-bootstrap/Badge';
import NewEntryModal from '../components/NewEntryModal';
import UploadCSVModal from '../components/UploadCSVModal';
import UpdateEntryModal from '../components/UpdateEntryModal';
import { CSVDownloader, CSVReader } from 'react-papaparse';
import DropdownButton from 'react-bootstrap/DropdownButton';
import DropdownItem from 'react-bootstrap/DropdownItem';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import ReactTooltip from 'react-tooltip';



const StudentLog = (props) => {
    //console.log("Student log props: "+ JSON.stringify(props.props.studentInfo.user_id));
    const [studentInfo, setStudentInfo] = useState(props.props.studentInfo);
    const [studentID, setStudentID] = useState(props.props.studentInfo.user_id);
    const [studentName, setStudentName] = useState(props.props.studentInfo.name);
    const [show, setShow] = useState(false);
    const [showCSVUpload, setShowCSVUpload] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);
    const [selectedStudents, setSelectedStudents] = useState([{studentName: props.props.studentInfo.name, studentID: props.props.studentInfo.user_id}]);
    const [logEntries, setLogEntries] = useState([]);
    const [logSearchTerm, setLogSearchTerm] = useState("");
    const [selectedEntry, setSelectedEntry] = useState({});
    const [sortSelection, setSortSelection] = useState("Sort by");
    const [sortDirection, setSortDirection] = useState(true);
    const sortKeys = {"Date": 1, "Author": 2, "Type": 3, "Contact": 4}
    const [studentLogTags, setStudentLogTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [showTagDropdown, setShowTagDropdown] = useState(false);
    const [isSelectingTags, setIsSelectingTags] = useState(false);
    const [tags, setTags] = useState([]);
    const [pageNum, setPageNum] = useState(0);
    const [logentryCount, setlogentryCount] = useState();
    const [pageSelectorOffset, setPageSelectorOffset] = useState(0);

    function removeTagDropdown(tag, e) {
        var copy = [...selectedTags];
        for(var i = 0; i < copy.length; i++)
        {
            //console.log("Searching for student: " + JSON.stringify(student));
            if(copy[i].tagID === tag.id)
            {
                //console.log("Found student at index: " + i);
                var spliced = selectedTags.splice(i, 1);
                //elem.classList.remove("selected");
                setSelectedTags([...selectedTags]);
                //console.log("Removed student: " + JSON.stringify(spliced));
            }
        }
        setIsSelectingTags(true);
        setShowTagDropdown(true);
        setPageNum(0);
        setPageSelectorOffset(0);
        e.stopPropagation();
        //console.log("I made it far");
        if(selectedTags.length > 0)
            filterSearch(selectedTags, 0, logSearchTerm);
        else
        {
            getLogEntries(studentID, 0, [], logSearchTerm);
            //getLogEntryCount(studentID);
        }
        return false;
    }

    const isTagSelected = (tag) =>{
        if(selectedTags.some(e=>e.tagID == tag.id)){
            //console.log("Student is selected");
            return true;
        }
    }

    async function getTags(){
        try {
            //console.log(props.props.match.params.courseID);
            var response_string = "/api/logentry/tags";
            //console.log(JSON.stringify(props.match.params.courseID));
            const response = await fetch(response_string);
            const jsonData = await response.json();
            //console.log("STUDENTS: " + JSON.stringify(jsonData));
            setTags(jsonData);
        }
        catch(err)
        {
            console.log("Error getting students in NewEntryModal.");
        }
    }

    const addToSelectedTags = (tag, e, shouldClose) =>{
        var arr = [...selectedTags];
        arr.push({tagID: tag.id, tagName: tag.tag});
        
        
        //selectedToTop();
        setSelectedTags(arr);
        if(!shouldClose)
        {
            setIsSelectingTags(true);
            setShowTagDropdown(true);
            e.stopPropagation();
        }
        else
        {
            setIsSelectingTags(false);
            setShowTagDropdown(false);
        }

        //filterFor(arr);
        setPageNum(0);
        setPageSelectorOffset(0);
        filterSearch(arr, 0, logSearchTerm);
        //console.log("Adding selected");
    }

    const getCookieValue = () => {
        let cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)user_name\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        return cookieValue;
    }

    const getUserID = () => {
        let cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)user\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        return cookieValue;
    }

    const getCookieRole = () => {
        let cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)role\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        return cookieValue;
    }

    const handleShow = () => {
        setShow(true);
    }

    const handleClose = () => {
        setShow(false);
        getLogEntries(studentID);
        setSelectedTags([]);
        getLogEntryCount(studentID);
        setLogSearchTerm('');
    }

    const handleUpdateClose = () =>{
        setSelectedEntry({});
        setShowUpdate(false);
        getLogEntries(studentID);
        setSelectedTags([]);
        getLogEntryCount(studentID);
        setLogSearchTerm('');
    }

    const handleCloseCSVModal = () => {
        setShowCSVUpload(false);
        getLogEntries(studentID);
        setSelectedTags([]);
        getLogEntryCount(studentID);
        setLogSearchTerm('');

    }

    const handleShowCSVModal = (e) =>{
        //console.log("Showing CSV Modal");
        setShowCSVUpload(true);
    }

    const handleUpdateLogEntry = (entry, student) =>{
        //console.log("Handle Update log entry");
        //console.log(JSON.stringify(entry));
        //console.log(JSON.stringify(student));
        setShowUpdate(true);
        setSelectedEntry({entry, student});
    }

    const getFormatted = (entries) =>{
        
        var formatted = [];
        for(var i = 0; i < entries.length; i++)
        {
            var entry = entries[i];
            //console.log(entry);
            formatted.push({
                ID: entry.key,
                Type: entry.props.type,
                Entry: entry.props.log_text,
                Submitted: entry.props.submitted,
                Contact: entry.props.contact,
                ContactDate: entry.props.contactdate,
                Author: entry.props.author,
            });
        }
        return formatted;
    }

    const splitContactDate = (date) =>{
        if(date)
            return date.split("T")[0].replace(/-/g, '\/');
        else
            return "2021/09/18";
    }
    

    const formatDate = (date, wTime, offset) =>{
        if(!offset)
            offset = 0;
        //console.log("Date: " + date);
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
          ];
        
        var split_date = date.split(" ");
        const dateObj = new Date(date);
        dateObj.setDate(dateObj.getDate() + offset);
        var hours = dateObj.getHours();
        var day_night;
        if(hours>=12)
        {
            if(hours != 12)
                hours=hours-12;
          day_night = "p.m.";
        }
        else
            day_night = "a.m.";
        var minutes = dateObj.getMinutes();
        if(minutes < 10)
          minutes = "0" + minutes;
        var time = hours + ":" + minutes;
        
        if(wTime)
            var formatted = months[dateObj.getMonth()] + " " + dateObj.getDate() + ", " + dateObj.getFullYear() + ", " + time + " " + day_night;
        else
            var formatted = months[dateObj.getMonth()] + " " + dateObj.getDate() + ", " + dateObj.getFullYear();
        return formatted;
    }

    const editLogSearchTerm = (e) => {
        setLogSearchTerm(e.target.value);
        if(e.target.value.length > 2)
        {
            setPageNum(0);
            setPageSelectorOffset(0);
            console.log("Searching..." + e.target.value);
            console.log(JSON.stringify(selectedTags));
            if(selectedTags.length > 0)
            {
                filterSearch(selectedTags, 0, e.target.value);
                return;
            }
            else
            {
                getLogEntries(studentID, 0, [], e.target.value);
                return;
            }
            
        }
        if(e.target.value.length == 0 && selectedTags.length == 0)
        {
            getLogEntries(studentID, pageNum);
            return;
        }
        else if(e.target.value.length == 0)
        {
            filterSearch(selectedTags, 0);
        }

        
    }

    const dynamicLogSearch = (e) =>{
        //console.log(logEntries);
        
        //return logEntries.filter(entry => entry.props.log_text.toLowerCase().includes(logSearchTerm.toLocaleLowerCase()) || entry.props.author.toLowerCase().includes(logSearchTerm.toLocaleLowerCase()));
    }

    async function deleteLogEntry(student, entry)
    {
        var data={student, entry};
        try {
            var request = "/api/students/logentry/delete";
            const rawResponse = await fetch(request, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            var jsonData = await rawResponse.json();
            if(jsonData.success)
                getLogEntries(student.studentID);
        }catch(err)
        {
            console.log(err);
        }
    }

    async function getTagsFor(entryID)
    {
        try {
            var request = "/api/logentry/tags/view";
            const rawResponse = await fetch(request, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({entryID: entryID})
            });
            const tags = await rawResponse.json();

            //console.log(JSON.stringify(tags.rows));

            return tags.rows;
        }
        catch(err)
        {
            console.log(err);
        }
    }

    const parseTagsFor = (entryID, arr) =>{
        var return_arr = [];
        for(var i = 0; i < arr.length; i++)
        {
            for(var j = 0; j < arr[i].length; j++)
            {
                //console.log(entryID);
                //console.log(arr[i][j].logentry_id);
                if(arr[i][j].logentry_id == entryID)
                {
                    return_arr.push(arr[i][j]);
                }
            }
                
        }
        //console.log("Parsed tabs: " + JSON.stringify(return_arr));
        return return_arr;
    }

    async function filterSearch(arr, pageSelection, searchTerm) {
        if(!pageSelection)
            pageSelection = 0;
        if(arr.length > 0)
        {
        try {
            var request = "/api/students/logentry/tagfilter";
            const rawResponse = await fetch(request, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({selectedTags: arr, studentID: studentID, search: searchTerm})
            });
            var jsonData = await rawResponse.json();
            //console.log(jsonData);
            var parsed = [];
            for(var i = 0; i < jsonData.length; i++)
                parsed.push(jsonData[i].entry_id);
            if(parsed.length > 0)
            {
                getLogEntries(studentID, pageSelection, parsed, searchTerm);
                //setlogentryCount(parsed.length);
            }
            else
                setLogEntries([]);
            }
            catch(err)
            {
                console.log(err);
            }
        }
        else
        {
        getLogEntries(studentID, 0);
        //getLogEntryCount(studentID);
        }
        //Check for success or error
        


        // if(selectedTags.length > 0)
        //     return logEntries.filter( function(entry) { 
        //         var found = false;
        //         for(var i = 0; i < selectedTags.length; i++)
        //         {
        //             if(entry.props.tags.some(tag => tag.tag_id == selectedTags[i].tagID))
        //                 found = true;
        //             else
        //                 return false;
        //         }
        //         return found;
        //     });
        //     else
        return [];
}

async function getLogEntryCount(studentID, tagFilter, searchValue) {
    //console.log("Waiting on entries for: " + JSON.stringify(student));
    //console.log(editingEntry);
    try {
        var request = "/api/students/logentry/count";
        const rawResponse = await fetch(request, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({studentID: studentID, tagFilter: tagFilter, search: searchValue})
        });
    //Check for success or error
    var jsonData = await rawResponse.json();
    console.log("LOG ENTRY COUNT: " + jsonData.rows[0].count);
    setlogentryCount(parseInt(jsonData.rows[0].count));
    }
    catch(err)
    {
        console.log("Error getting log entry count for " + studentID);
    }
}


    async function getLogEntries(studentID, currentPage, tagFilter, searchValue) {
        //console.log("Waiting on entries for: " + JSON.stringify(student));
        //console.log(editingEntry);
        console.log("Getting new page of entries: " + currentPage);
        try {
            var request = "/api/students/logentry/view";
            const rawResponse = await fetch(request, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({studentID: studentID, offset: currentPage, tagFilter: tagFilter, search: searchValue})
            });
        //Check for success or error
        var jsonData = await rawResponse.json();
        //sconsole.log(JSON.stringify(jsonData));
        
        
        var arr = [];
        for(var i = 0; i < jsonData.length; i++)
        {   
            arr.push(await getTagsFor(jsonData[i].entry_id));
        }
        
        setStudentLogTags([...arr]);
        //console.log(JSON.stringify(studentLogTags));
        
        setLogEntries(jsonData.map(entry=>(
                    
                    <div className="entry" log_text={entry.entry_text} key={entry.entry_id} type={entry.entry_type} author={entry.name} submitted={entry.submission_date} contact={entry.contact.toString()} contactdate={entry.contact_date} tags={parseTagsFor(entry.entry_id, arr)}>
                        <ReactTooltip />
                        <div className="head">
                            
                            <div className="subject">{entry.entry_subject}</div>
                            <div className="entry-info-wrap">
                            <div className="badge-wrap">
                            {/* {entry.contact?
                            
                            // <div className="contact">
                            //     <Badge variant="dark" >Two-way Contact <br />
                            //     {formatDate(splitContactDate(entry.contact_date))}
                            //     </Badge> 
                            // </div>
                            :   */}
                            <div className="contact">
                            
                            </div>
                            
                            <div className="type">{entry.entry_type == "Public"?<Badge variant="success">Type: Public</Badge>: <Badge variant="danger">Type: Internal</Badge>}</div>
                            </div>
                            
                            {/* <div className="submitted-at">{formatDate(entry.submission_date, true)}</div> */}
                            <div className="submitted-at">{
                                
                                entry.contact_date? formatDate(entry.contact_date, false, 1)
                                :formatDate(entry.submission_date, true)}</div>
                            <div className="author" data-tip={formatDate(entry.submission_date, true)}>{entry.name}</div>
                            {entry.is_pinned == true&&
                            <div className="is-pinned"><FontAwesomeIcon icon={faThumbtack}/>  Pinned</div>}
                            {entry.author_id == getUserID() || getCookieRole().includes('Administrator')?
                            <div className="edit-entry">
                            <FontAwesomeIcon className="edit-button" onClick={(e) => handleUpdateLogEntry(entry, selectedStudents[0])}icon={faEdit}/>
                            <div className="delete-entry" onClick={function(){if(window.confirm('Are you sure you want to delete this log entry?')){deleteLogEntry(selectedStudents[0], entry)}}}>
                                <FontAwesomeIcon className="delete-button" icon={faTrash}/>
                            </div>
                            </div>
                            :""}
                        </div>
                        
                        </div>
                        <div className="tag-display">
                                {
                                    parseTagsFor(entry.entry_id, arr).map((tag, index) => (
                                        <Badge style={tag.tag.toLowerCase() !== 'confidential'? {backgroundColor: "#d6d6d6", margin: "1px"} : {backgroundColor: "#ff5447", color: "white", margin: "1px"}} key={index} >{tag.tag}<div className="contact-date-tag" style={{display: 'none'}}>{tag.id==1?formatDate(splitContactDate(entry.contact_date)): ""}</div></Badge>
                                    ))
                                }
                            </div>
                        <div className="body">
                        {entry.entry_text}
                        </div>
                        {
                        <div className="foot">
                            <div className="entry-edited-at">
                                Submitted: {formatDate(entry.submission_date, true)}<br/>
                                {entry.last_update != entry.submission_date&&
                                <div>
                                Edited: {formatDate(entry.last_update, true)}
                                </div>
                                }
                            </div>
                        </div>

                        }
                    </div>   
        )));
        setlogentryCount(jsonData[0].full_count);
        
        }
        catch(err){
            console.log("Error on submit: " + err);
        }
    }

    async function updateLogEntry()
    {
        try {
            var request = "/api/students/logentry/update";
            const rawResponse = await fetch(request, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(editingEntry)
            });
            var jsonData = await rawResponse.json().then(handleLogClose()).then().then(handleLogShow());

            //getLogEntries(selectedStudents[0]);
            //handleLogShow();
            
        }catch(err)
        {
            console.log(err);
        }
    }

    const editLogEntry = (e, student) =>
    {
        //var editingEntryHolder = entry.entry_id;
        editingEntry.text = e.entry_text;
        editingEntry.id = e.entry_id;
        editingEntry.type = e.entry_type;
        editingEntry.contact = e.contact;
        editingEntry.contactDate = e.contact_date;
        getLogEntries(student);

    }

    const updateEntryText = (e) => {
        //setUpdatedEntryText(e.target.value);
        editingEntry.text = e.target.value;
        console.log(editingEntry.text);
        //getLogEntries(student);
    }

    const handleEntryUpdate = (e, student) =>{
        updateLogEntry(student);
    }

    const handleUpdateEntryType = (e) =>{
        editingEntry.type = e.target.value;
        
    }

    const handleUpdateContact = (e) =>{
        editingEntry.contact = !editingEntry.contact;
        e.target.checked = editingEntry.contact;
    }

    const handleContactDateUpdate = (e) =>{
        editingEntry.contactDate = e.target.value;
    }

    const handleSortSelect = (e) =>{
        console.log("Handling sort select");
        console.log(e);
        sortLogsBy(e);
        console.log(sortSelection);
    }

    const sortLogsBy = (type) =>{
        var obj = [...logEntries];
        switch(parseInt(type))
        {
            case 1:
                    if(sortDirection)
                    {  
                        obj.sort(function(a, b){
                            return a.props.contactdate > b.props.contactdate?-1:1;
                        });
                    } 
                    else
                    {
                        obj.sort(function(a, b){
                            return a.props.contactdate > b.props.contactdate?1:-1;
                        });
                    }
                setSortSelection("Date");
            break;
            case 2:
                    if(sortDirection)
                    {  
                        obj.sort(function(a, b){
                            return a.props.author > b.props.author?1:-1;
                        });
                    } 
                    else
                    {
                        obj.sort(function(a, b){
                            return a.props.author > b.props.author?-1:1;
                        });
                    }
                setSortSelection("Author");
            break;
            case 3:
                    if(sortDirection)
                    {  
                        obj.sort(function(a, b){
                            return a.props.type > b.props.type?1:-1;
                        });
                    } 
                    else
                    {
                        obj.sort(function(a, b){
                            return a.props.type > b.props.type?-1:1;
                        });
                    }
                setSortSelection("Type");
            break;

            case 4:
                    if(sortDirection)
                    {  
                        obj.sort(function(a, b){
                            return a.props.contact > b.props.contact?1:-1;
                        });
                    } 
                    else
                    {
                        obj.sort(function(a, b){
                            return a.props.contact > b.props.contact?-1:1;
                        });
                    }
                setSortSelection("Contact");
            break;
            default:
                // setSortSelection("Sort by");
                // obj.sort(function(a, b){
                //     return a.props.submitted > b.props.submitted?1:-1;
                // });
                // setSortDirection(true);
                window.location.reload(false);
            break;
        }
        setLogEntries(obj);
    }

    function selectPage (pageSelection)
    {
        //console.log(pageSelection + " | " + JSON.stringify(selectedTags));
        if(Math.ceil(logentryCount/50) > 5)
        {
            if(pageSelection == 0)
                setPageSelectorOffset(0);
            else if (pageSelection == Math.ceil(logentryCount/50) - 1)
            {
                setPageSelectorOffset(Math.ceil(logentryCount/50) - 5);
            }
        }
        else
            setPageSelectorOffset(0);
        setPageNum(pageSelection);
        window.scrollTo(0, 0);
        if(selectedTags.length > 0)
        {
            console.log("Selected page with filter active...");
            filterSearch(selectedTags, pageSelection, logSearchTerm);
        }
        else
        {
            getLogEntries(studentID, pageSelection, [], logSearchTerm);
        }
            //studentID, currentPage, tagFilter, searchValue
    }

    function pageSelector(){
       let rows = [];
       rows.push(<div className="page-num" onClick={() => selectPage(0)}> First </div>);
       rows.push(<div className="page-num" onClick={() => incrementPageSelectorOffset(-1)}> {'<'} </div>);
       
       var counter = 0;
       if(Math.ceil(logentryCount/50) < 5)
       {
           for(let i = 0; i < Math.ceil(logentryCount/50); i++)
           {
            if(pageNum == i)
                rows.push(<div className="page-num selected" key={i} onClick={()=> selectPage(i)}>{i + 1}</div>);
            else
                rows.push(<div className="page-num" key={i} onClick={()=> selectPage(i)}>{i + 1}</div>);
           }
       }
       else
       {
            for(let i = pageSelectorOffset; i < logentryCount/50 && i >= 0; i++)
            {
                if(counter < 5 && i >= 0)
                {
                    if(pageNum == i)
                        rows.push(<div className="page-num selected" key={i} onClick={()=> selectPage(i)}>{i + 1}</div>);
                    else
                        rows.push(<div className="page-num" key={i} onClick={()=> selectPage(i)}>{i + 1}</div>);
                    
                    counter++;
                }
            }
        }
       
       rows.push(<div className="page-num" onClick={() => incrementPageSelectorOffset(1)}>{'>'}</div>);
       rows.push(<div className="page-num" onClick={() => selectPage(Math.ceil(logentryCount/50) - 1)}> Last </div>);
       return rows;
    }

    const incrementPageSelectorOffset = (increment) =>
    {
        var change = pageSelectorOffset + increment;
        if(change < 1)
        {
            change = 0;
        }
        if(change <= (logentryCount/50) - 4)
        {
            setPageSelectorOffset(change);
        }

        if(pageNum + increment >= 0 && (pageNum + increment) <= (logentryCount/50))
        {
            selectPage(pageNum + increment);
            setPageNum(pageNum + increment);
        }
        
    }

    const handleSortDirection = (e) =>{
        setSortDirection(!sortDirection);
        sortLogsBy(sortKeys[sortSelection]);
    }

    const isStudent = () =>
    {
        //console.log("Role check: " + getCookieRole());
        if(getCookieRole().toLowerCase().includes("administrator") || getCookieRole().toLowerCase().includes("instructor"))
            return false;
        return true;
    }

    const clearFilterSelection = () =>{
        setSelectedTags([]);
        getLogEntries(studentID);
        getLogEntryCount(studentID);
    }
    
    useEffect(() => {
        getLogEntries(studentID);
        setShow();
        getTags();

    }, []);

    return(
        <Fragment>
            
            {show&&
            <NewEntryModal inputRef={handleClose} props={{show: show, studentInfo: studentInfo, courseID: props.props.courseID}}></NewEntryModal>
            }
            {showCSVUpload&&
            <UploadCSVModal inputRef={handleCloseCSVModal}props={{show: showCSVUpload}}></UploadCSVModal>
            }
            {
            (JSON.stringify(JSON.parse("{}")) !== JSON.stringify(selectedEntry) && showUpdate) &&
            <UpdateEntryModal inputRef={handleUpdateClose} props={{showUpdate, selectedEntry}}></UpdateEntryModal>   
            }
            <div className="student-log">
            <h4>Student Log for {studentName}</h4>
            <div className="search-wrapper">
            <div className="tag-selector">
                        <DropdownButton title={selectedTags.length>0 ? selectedTags[0].tagName + " + " + (selectedTags.length - 1) + " others" : "Filter for:"}>
                            {
                                tags.map(tag => (
                                    
                                    <Dropdown.Item key={tag.id} onClick={isTagSelected(tag)? function (e){ removeTagDropdown(tag, e, true)}: function (e) {addToSelectedTags(tag, e, true)}} style={isTagSelected(tag)&&{backgroundColor: "#007bff", color: "white", borderTopStyle: "solid", borderTopColor: "white"}}>{tag.tag}
                                    {isTagSelected(tag)?
                                    <div className="dropdown-selection" onClick={function (e) { removeTagDropdown(tag, e, false) }}><FontAwesomeIcon icon={faMinus}></FontAwesomeIcon></div>
                                    :
                                    <div className="dropdown-selection" onClick={function (e) { addToSelectedTags(tag, e, false)}}><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon></div>
                                    }
                                    </Dropdown.Item>
                                ))
                            }
                            <Dropdown.Item onClick={() => clearFilterSelection()}>Clear</Dropdown.Item>
                        </DropdownButton>
                    </div>    
            <div className="sort-button-wrapper">
            <DropdownButton style={{borderTopRadius: "0px"}}className="sort-dropdown" title={sortSelection} onSelect={handleSortSelect}>
                <DropdownItem eventKey="1">Date</DropdownItem>
                <DropdownItem eventKey="2">Author</DropdownItem>
                {(!isStudent())&&<DropdownItem eventKey="3">Type</DropdownItem>}
                <DropdownItem eventKey="4">Contact</DropdownItem>
                <Dropdown.Divider />
                <DropdownItem eventKey="5">Clear</DropdownItem>
            </DropdownButton>
            <Button className="sort-direct" onClick={handleSortDirection}><FontAwesomeIcon icon={sortDirection?faArrowUp:faArrowDown}></FontAwesomeIcon></Button>
            </div>
            
            <input className="log-search-bar" type="text" onChange={editLogSearchTerm} value={logSearchTerm.value} placeholder='Search...'/>
            <FontAwesomeIcon className="search-icon" icon={faSearch}/>
            </div>
            
            {(!isStudent())?
            <div className="log-entry-buttons">
                <button className="log-entry-button" onClick={handleShow}>+ Add Log Entry</button>
                <button className="log-entry-button" onClick={handleShowCSVModal}><FontAwesomeIcon icon={faUpload}/>Upload CSV</button>
                <CSVDownloader className="log-entry-button"
        data={getFormatted(logEntries)}
        type="button"
        filename={selectedStudents.length>0?selectedStudents[0].studentName +"_entries":"BLANK"}
        bom={true}
      ><FontAwesomeIcon icon={faDownload}/>Download CSV</CSVDownloader>
            </div>:<div></div>}
            {logEntries}
            </div>
            <div className="page-selector">
            {pageSelector()}
            </div>
        </Fragment>
        
    )
}

export default StudentLog;