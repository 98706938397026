import React, { Fragment, useState, useEffect } from "react";
import FlipMove from 'react-flip-move';
import { useHistory } from "react-router-dom";
import { faArrowDown, faArrowUp, faFileCsv, faAddressBook, faChartBar, faSpinner, faRedo, faBackward, faArrowLeft, faLeaf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import { CSVLink, CSVDownload } from "react-csv";
import Modal from 'react-bootstrap/Modal';
import DropdownButton from 'react-bootstrap/DropdownButton';
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import ManageTagModal from "./ManageTagModal";
import axios from "axios";


const Instructors = () => {

    //var server_string = "https://a0cfe9a22b3c.ngrok.io"
   
    const [instructors, setInstructors] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [instructs, setInstructs] = useState([]);
    const [instructorcourses, setInstructorcourses] = useState([]);
    const history = useHistory({forceRefresh:true});
    const [participationSort, setParticipationSort] = useState(false);
    const [ungradedSort, setUngradedSort] = useState(false);
    const [courseCountSort, setCourseCountSort] = useState(false);
    const [nameSort, setNameSort] = useState(false);
    const [messagesSort, setMessagesSort] = useState(false);
    const [updatesSort, setUpdatesSort] = useState(false);
    const [entriesSort, setEntriesSort] = useState(false);
    const [csvRepData, setCsvRepData] = useState([]);
    const [courseCSVData, setCourseCSVData] = useState([]);
    const [studentCSVData, setStudentCSVData] = useState([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [onDeamandModalIsOpen, setOnDemandModalIsOpen] = useState(false);
    const [withdrawnStudentModalIsOpen, setwithdrawnStudentModalIsOpen] = useState(false);
    const [selectedInstructor, setSelectedInstructor] = useState(0);
    const [logEntriesCSV, setLogEntriesCSV] = useState([]);
    const [showManageTagModal, setShowManageTagModal] = useState(false);
    const [gradeCutoff, setGradeCutoff] = useState('');
    const [previousReports, setPreviousReports] = useState([]);
    const [reportIsRunning, setReportIsRunning] = useState(false);
    const [schemeSelect, setSchemeSelect] = useState('2');
    const [withdrawnStudents, setWithdrawnStudents] = useState([]);


    const courseSearch = (name) => {
        //var values = name.split(",");
        //var f_name = values[0];
        //var l_name = values[1] ? name.substr(name.indexOf(' ') + 1) : '';
        //console.log(f_name);
        //console.log(l_name);
        console.log("Name: " + name);
        history.push("/AdminHome", {params: '2', search: ''+ name});
    }

    // <option value="participation">Participation</option>
    //             <option value="instructor">Instructor</option>
    //             <option value="ungraded-count">Ungraded</option>

    const sortClick = (type) => {
        var obj = [...instructs];
        if(type==="ungraded-count")
        {
            if(ungradedSort)
            {  
                obj.sort(function(a, b){
                    return a.props.ungraded > b.props.ungraded?1:-1;
                });
            } 
            else
            {
                obj.sort(function(a, b){
                    return a.props.ungraded > b.props.ungraded?-1:1;
                });
            }
            setUngradedSort(!ungradedSort);
        }
        if(type==="course-count")
        {
            if(courseCountSort)
            {  
                obj.sort(function(a, b){
                    return a.props.courses > b.props.courses?1:-1;
                });
            } 
            else
            {
                obj.sort(function(a, b){
                    return a.props.courses > b.props.courses?-1:1;
                });
            }
            setCourseCountSort(!courseCountSort);
        }
        if(type==="name")
        {
            if(nameSort)
            {
                obj.sort(function(a, b){  
                return a.props.dataid.toLowerCase().localeCompare(b.props.dataid.toLowerCase());
                });
            } 
            else
            {
                obj.sort(function(a, b){  
                return b.props.dataid.toLowerCase().localeCompare(a.props.dataid.toLowerCase());
                });
            }
            setNameSort(!nameSort);
        }
        if(type==="messages")
        {
            if(messagesSort)
            {  
                obj.sort(function(a, b){
                    return a.props.messages - b.props.messages;
                });
            } 
            else
            {
                obj.sort(function(a, b){
                    return b.props.messages - a.props.messages;
                });
            }
            setMessagesSort(!messagesSort);
        }
        if(type==="updates")
        {
            if(updatesSort)
            {  
                obj.sort(function(a, b){
                    return a.props.updates - b.props.updates;
                });
            } 
            else
            {
                obj.sort(function(a, b){
                    return b.props.updates - a.props.updates;
                });
            }
            setUpdatesSort(!updatesSort);
        }
        if(type==="entries")
        {
            if(entriesSort)
            {  
                obj.sort(function(a, b){
                    return a.props.entries - b.props.entries;
                });
            } 
            else
            {
                obj.sort(function(a, b){
                    return b.props.entries - a.props.entries;
                });
            }
            setEntriesSort(!entriesSort);
        }
        setInstructs(obj);
    }

    const getInstructorUpdates = async(id) =>{
        var response_string = "";
        response_string = "/api/instructors/updatecount?id=" + id;
        try {
            const response = await fetch(response_string,{
                credentials: 'include',
                method: 'GET'
                
            });
            const jsonData = await response.json();
            return {avg: jsonData[0].avg};
        }
        catch(err)
        {
            console.error(err.message);
        }
    }

    const getInstructorLogEntryCount = async() =>{
        var response_string = "";
        response_string = "/api/instructors/entrycount";
        try {
            const response = await fetch(response_string,{
                credentials: 'include',
                method: 'GET'
                
            });
            const jsonData = await response.json();
            return jsonData;
        }
        catch(err)
        {
            console.error(err.message);
        }
    }

    var getObjectByValue = function (array, key, value) {
        
        var obj = array.filter(function (object) {
            if(object[key] === value)
                return object; 
        });
        try{
        //console.log("FOUND IT: " + Number(obj[0].avg).toFixed(2));
        return Number(obj[0].avg).toFixed(2);
        }
        catch(err)
        {
            return 0;
        }
    };

    var getEntryCount = function (array, id) {
        
        for(var i = 0; i < array.length; i++)
            if(array[i].author_id == id)
                return array[i].count;
        return 0;
    };

    const buildCSVDownload = (data, updateData) =>{
        // setCsvRepData(data.map(student => [
        //     student.user_id,
        //     student.name,
        //     student.missing_assignments,
        //     100 - (student.student_participation * 100),
        //     student.trend
        // ]));

        // key={instructor.user_id} 
        //         dataid={instructor.name}
        //         name={instructor.name} 
        //         courses={instructor.course_count} 
        //         ungraded={instructor.ungraded_assignments}
        //         updates={getObjectByValue(updateData, "instructor_id", instructor.user_id)}
        //         messages={instructor.messages_sent}

        var temp = data.map(instructor => [
            instructor.user_id,
            instructor.name,
            instructor.course_count,
            instructor.ungraded_assignments,
            getObjectByValue(updateData, "instructor_id", instructor.user_id),
            instructor.messages_sent
        ]);

        temp.unshift(['userID', 'name', 'course_count', 'ungraded', 'updates', 'conversations']);

        setCsvRepData([...temp]);
        
    }

    const getWithdrawnStudents = async()=>{
        var response_string = "/api/students/withdrawn";
        const response = await fetch(response_string,{
            credentials: 'include',
            method: 'GET'
        });
        const jsonData = await response.json();
        
        setWithdrawnStudents(jsonData.rows);
    }


    const getInstructors = async(type) => {
        //console.log("Getting instructors with sort: " + type);
        var response_string = "";
        try {
            if(type)
            {
                response_string = "/api/instructors?sort_type=" + type;
                
            }
            else
                response_string = "/api/instructors";
            
            const response = await fetch(response_string,{
                credentials: 'include',
                method: 'GET'
            });
            //console.log(response);
            const jsonData = await response.json();

            
            var updates_string = "";
            updates_string = "/api/instructors/updatecount";
            const updateResponse = await fetch(updates_string,{
                credentials: 'include',
                method: 'GET'
            });
            const updateData = await updateResponse.json();

            const entryCountData = await getInstructorLogEntryCount();
            console.log(JSON.stringify(entryCountData));

            console.log(JSON.stringify(withdrawnStudents));



            // const count = updateData.find(user => user.instructor_id === instructor.user_id).avg
            // console.log("Updates count: " + count);
            //console.log("Update data: " + JSON.stringify(updateData));

            //console.log("Instructors: " + JSON.stringify(jsonData));
            setInstructors(jsonData);
            //console.log("Instructors: " + JSON.stringify(jsonData));
            setInstructs(jsonData.map(instructor => 
                <div className="instructor" 
                key={instructor.user_id} 
                dataid={instructor.name}
                name={instructor.name} 
                courses={instructor.course_count} 
                ungraded={instructor.ungraded_assignments}
                updates={getObjectByValue(updateData, "instructor_id", instructor.user_id)}
                entries={getEntryCount(entryCountData, instructor.user_id)}
                messages={instructor.messages_sent}
                >
                    
                                <div className="person">
                                <div className="profile-pic">
                                        <img src={instructor.avatar_url}></img>
                                    </div>
                                    <div className="name" onClick={(e) => courseSearch(instructor.user_id)}>
                                    {instructor.name}
                                    </div>
                                    
                                </div>
                                    <div className="course-count">
                                        
                                        <div className="count-text">
                                    {instructor.course_count}
                                        </div>
                                </div>
                                <div className="ungraded-assignments">
                                    
                                    {instructor.ungraded_assignments > 100 &&
                                    <div className="ungraded-count" style={{color: "#ffc107"}}>
                                        {instructor.ungraded_assignments}
                                    </div>
                                    }
                                    {instructor.ungraded_assignments < 100 &&
                                    <div className="ungraded-count">
                                        {instructor.ungraded_assignments}
                                    </div>
                                    }
                                </div>
                                <div className="updates-per-week">
                                    
                                    <div className="updates-count">
                                        {getObjectByValue(updateData, "instructor_id", instructor.user_id)}
                                        {/* {JSON.stringify(updateData.find(block => block.instructor_id == instructor.user_id))} */}
                                    </div>
                                </div>
                                <div className="updates-per-week">
                                    
                                    <div className="updates-count">
                                        {instructor.messages_sent}
                                    </div>
                                </div>
                                <div className="updates-per-week">
                                    
                                    <div className="updates-count">
                                        {getEntryCount(entryCountData, instructor.user_id)}
                                    </div>
                                </div>

                                
                                {/* <div className="participation-container">
                                    Participation:
                                    <div className="participation-bar">
                        {instructor.participation > 75 &&
                        <ProgressBar striped animated variant="success" now={instructor.participation}  />
                        }
                        {instructor.participation < 75 && instructor.participation > 60 &&
                        <ProgressBar striped animated variant="warning" now={instructor.participation} />
                        }
                        {instructor.participation < 60 &&
                        <ProgressBar striped animated variant="danger" now={instructor.participation} />
                        }
                        </div>
                                </div> */}
                            </div>));
                            buildCSVDownload(jsonData, updateData);
                           
        } catch (err) {
            console.error(err.message);
        }
    }

    

    const getInstructorCourses = async(type) => {
        console.log("Getting instructors with sort: " + type);
        var response_string = "";
        try {
            if(type)
            {
                response_string = "http://localhost:5000/instructorcourses?sort_type=" + type;
                
            }
            else
                response_string = "http://localhost:5000/instructorcourses";
            const response = await fetch(response_string,{
                credentials: 'include',
                method: 'GET'
            });
            const jsonData = await response.json();
            
            //console.log(jsonData);
            setInstructorcourses(jsonData);
        } catch (err) {
            console.error(err.message);
        }
    }

    const editSearchTerm = (e) => {
        
        setSearchTerm(e.target.value);
        
    }

    const editGradeCutoff = (e) =>{
        setGradeCutoff(e.target.value);
    }

    const dynamicSearch = () => {
        return instructs.filter(instruct => instruct.props.dataid.toLowerCase().includes(searchTerm.toLowerCase()));
    }

    const openModal = () => {
        console.log("Modal open");
        getCourseCSVData();
        getStudnentCSVData();
        setIsOpen(true);
      }

      const afterOpenModal = () => {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
      }
    
      const closeModal = () => {
        setIsOpen(false);
      }

      

      const buildCourseSettingsModal = () => {
          return (
            <div className='reports-modal'>
            <Modal 
            show={modalIsOpen}
            onHide={closeModal}
           
        >
            <Modal.Header><strong>CSV Reports</strong></Modal.Header>
            <Modal.Body>
                <div className='reports-modal-body'>
                <div className='instructor-report-options'>
                    <div className='report-options-title'>Instructors</div>
            <CSVLink filename={ new Date().toDateString() + "-instructors-report.csv"} data={csvRepData}><button className='btn-primary'>Instructors Overall</button></CSVLink><br /><br />
            <div style={{fontSize: '14px'}}>
            Log Entries by Instructor:
            <div className="instructor-log-selection"><DropdownButton className='btn-primary' title={selectedInstructor !=0 ? selectedInstructor : 'NONE'}>
                {
                instructors.map(instruct => (
                    <DropdownItem onSelect={() => buildLogEntryLink([instruct.user_id, instruct.name])}>{instruct.name}</DropdownItem>
                ))
                }
            </DropdownButton>
            </div>
            {selectedInstructor != 0 ?
            <div><CSVLink filename={ new Date().toDateString() + "-logentry-report.csv"} data={logEntriesCSV}><button className='btn-secondary'>Download</button></CSVLink><br /></div> : <div></div>
            }
            </div>
            </div>
            <div className='instructor-report-options'>
            <div className='report-options-title'>Courses</div>
            <CSVLink filename={ new Date().toDateString() + "-courses-report.csv"} data={courseCSVData}><button className='btn-primary'>Courses Overall</button></CSVLink><br /><br />
            </div>
            <div className='instructor-report-options'>
            <div className='report-options-title'>Students</div>
            <CSVLink filename={ new Date().toDateString() + "-students-report.csv"} data={studentCSVData}><button className='btn-primary'>Students Overall</button></CSVLink><br /><br />
            <div style={{fontSize: '14px'}}>On demand grade reporting</div>
            <button className='btn-primary' onClick={()=>openOnDemandModal()}>Student Grades</button>
            <div style={{fontSize: '14px'}}>Log Recovery</div>
            <button className='btn-primary' onClick={()=>openOnWithdrawnRecoveryModal()}>Withdrawn Students</button>
            </div>
            </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
        </Modal>
        </div>
          );
      }

      const buildOnDemandReportsModal = () =>{
          return(
            <div className='reports-modal'>
            
            <Modal className='on-demand-modal'
            show={onDeamandModalIsOpen}
            onHide={closeOnDemandModal}
            >
            <Modal.Header><ReactTooltip /><div data-tip={'Back to reports home'} onClick={closeOnDemandModal} style={{cursor: 'pointer'}}><FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon> Reports</div><Modal.Title>Upper Bounds Grade Report</Modal.Title><FontAwesomeIcon style={{cursor: 'pointer'}} data-tip={'Refresh'} icon={faRedo} onClick={() => refreshDemandsModal()}></FontAwesomeIcon> </Modal.Header>
            <Modal.Body>
            
                New upper bounds grade report
                <div className='on-demand-body'>
                <div className='scheme-selector'>
                    <select disabled={reportIsRunning} onChange={(e) => updateSchemeSelect(e)} value={schemeSelect}>
                        <option value='1'>Traditional (%)</option>
                        <option value='2'>Standards Based</option>
                    </select>
                </div>
                {schemeSelect=='2'?<input className="grade-report-cutoff" disabled={reportIsRunning} type="text" onChange={editGradeCutoff} value={gradeCutoff.value} placeholder='1-4'/>:
                <input className="grade-report-cutoff" disabled={reportIsRunning} type="text" onChange={editGradeCutoff} value={gradeCutoff.value} placeholder='1-100'/>}
                
                
                {reportIsRunning? 
                <div><button className='btn-primary disabled' style={{backgroundColor: '#768399', borderColor: '#768399'}} disabled={reportIsRunning} onClick={()=>{runReport()}}>Running  <FontAwesomeIcon className='running-spinner' icon={faSpinner}/></button>
                </div>
                : <button className='btn-primary' disabled={reportIsRunning} onClick={()=>{runReport()}}>Run Report</button>}
                </div>
                {reportIsRunning&&<div style={{fontSize:'12px', borderRadius:'2px', backgroundColor:'#d9d9d9'}}>Upper bounds grade report is running. Check back in 5-10 minutes. You may need to refresh the page.</div>}
                <div className='on-demand-previous'><br></br>
                    <div style={{fontWeight: 'bold'}}>Complete Reports</div>
                    <div className='table'>
                    
                        {previousReports.map(report => (
                            <div className='row'>
                                <div style={{width: '100px'}}>{report.split(' ')[0]}</div>
                                <div className='col'>
                                <button className='btn-primary' data-tip={'download'} onClick={()=>{downloadGradeBelowReport(report)}}><FontAwesomeIcon icon={faArrowDown}/></button>
                                </div>   
                            </div>
                        ))}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div style={{width: '100%', justifyContent: 'flex-start', fontSize: '12px'}}>Reports will be deleted after 5 weeks</div>
            </Modal.Footer>
            </Modal>
            </div>
          )
      }

      const buildWithdrawnStudentLogsModal = () =>{
        return(
          <div className='reports-modal'>
          
          <Modal className='on-demand-modal'
          show={withdrawnStudentModalIsOpen}
          onHide={closeWithdrawnStudentModal}
          >
          <Modal.Header><ReactTooltip /><div data-tip={'Back to reports home'} onClick={closeWithdrawnStudentModal} style={{cursor: 'pointer'}}><FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon> Reports</div><Modal.Title>Withdrawn Student Log Recovery</Modal.Title> </Modal.Header>
          <Modal.Body>
          
          
            <div className='on-demand-withdrawn' style={{height: '100%', overflowY: 'scroll'}}>
                {
                withdrawnStudents.map(student => (
                    <div style={{display: 'flex', width: '100%', alignContent: 'space-evenly'}}><div style={{width: '50%', borderBottomStyle: 'solid', borderBottomWidth: '1px'}} >{student.name}</div> <button className='btn-primary' onClick={()=>{downloadEntriesFor(student.user_id)}}><FontAwesomeIcon icon={faArrowDown}/></button></div>
                ))
                }
            </div>
          
           
              
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
          </Modal>
          </div>
        )
    }

    const downloadEntriesFor = async(id) =>{
        console.log("DOWNLOADING ENTRIES!");
        try{
            const previousReportsRequest = '/api/logentry/down?id='+parseInt(id);
            const response = await fetch(previousReportsRequest,{
                credentials: 'include',
                method: 'GET'
            });
            const jsonData = await response.json();

            var hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(ConvertToCSV(jsonData));
            hiddenElement.target = '_blank';
            hiddenElement.download = 'user_' + id + 'withdrawn_logs.csv';
            hiddenElement.click();
            hiddenElement.remove();

        }
        catch(err)
        {
            console.log("Error downloading reports");
        }
    }

    function ConvertToCSV(objArray) {
        var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        var str = '';

        for (var i = 0; i < array.length; i++) {
            var line = '';
            for (var index in array[i]) {
                if (line != '') line += ','

                line += array[i][index];
            }

            str += line + '\r\n';
        }

        return str;
    }

      const updateSchemeSelect = (e) =>{
          console.log(e.target.value);
          setSchemeSelect(e.target.value);
      }

      const refreshDemandsModal = () =>{
          closeOnDemandModal();
          openOnDemandModal();
      }

      const downloadGradeBelowReport = async(name) =>
      {
        axios({
            url: '/api/reports/down',
            params: {
                repname: name,
            }, 
            method: 'GET',
            responseType: 'blob', // important
            
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
          });
      }

      const getPreviousReports = async() =>{
        try{
        const previousReportsRequest = '/api/reports'
        const response = await fetch(previousReportsRequest,{
            credentials: 'include',
            method: 'GET'
        });
        //console.log(response);
        const jsonData = await response.json();
        console.log(jsonData);
        setPreviousReports(sortReports(jsonData.files));
        setReportIsRunning(jsonData.isRunning);
        }
        catch(err)
        {
            console.log("Error getting previous reports: " + err);
        }
      }

      const runReport = async() =>{
        if(verifyReportFields())
        {
            if(checkForDuplicateReport())
            {
                try{
                    const runReportRequest = '/api/reports/run?scheme=' + schemeSelect + '&upperbounds=' + gradeCutoff 
                    const response = await fetch(runReportRequest,{
                        credentials: 'include',
                        method: 'GET',
                    });
                    const jsonData = await response.json();
                    setReportIsRunning(jsonData.isRunning);
                }
                catch(err)
                {
                    console.log(err);
                }
            }
        }
        else
            alert('Report failed to start. Upper bound is invalid or report is already running.');
      }

      const sortReports = (fileNames) =>{
        fileNames.map(reverseDateRepresentation)
        .sort()
        .map(reverseDateRepresentation);
        return fileNames.reverse();
      }

      const checkForDuplicateReport = () =>{
          var error = false;
          for(var i = 0; i < previousReports.length; i++)
          {
              console.log(previousReports[i].split(' ')[0] + " | " + todaysDate());
              if(previousReports[i].split(' ')[0] == todaysDate())
                error = true;    
          }
          if(error)
          {
              return confirm("There already appears to be a report for this date. Continuing will replace the current report. Would you like to replace the current report?")
          }
          return true;
      }

      function todaysDate()
      {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = mm + '-' + dd + '-' + yyyy;
        return today;
      }

      const reverseDateRepresentation = date => {
        let parts = date.split('-');
        return `${parts[2]}-${parts[1]}-${parts[0]}`;
      };

      const verifyReportFields = () =>{
          if(!reportIsRunning)
            if(!isNaN(gradeCutoff))
            {
                if(schemeSelect == 1)
                {
                    if(gradeCutoff >=1 && gradeCutoff <=105)
                        return true;
                    else
                        return false;
                }
                if(schemeSelect == 2)
                {
                    if(gradeCutoff >=1.0 && gradeCutoff <=4.0)
                        return true;
                    else
                        return false;
                }
            }
            return false;
      }
    
      const openOnDemandModal = async() =>{
          getPreviousReports();
          setOnDemandModalIsOpen(true);
      }

      const openOnWithdrawnRecoveryModal = async() =>{
          setwithdrawnStudentModalIsOpen(true);
      }

      const closeOnDemandModal = () =>{
          setOnDemandModalIsOpen(false);
      }

      const closeWithdrawnStudentModal = () =>{
          setwithdrawnStudentModalIsOpen(false);
      }

      const buildLogEntryLink = async(user) =>{
        
        try {
            const response = await fetch("/api/logentry/author?id=" + user, {
                method: 'GET',
                credentials: 'include'
            });
            const jsonData = await response.json();

            var temp = jsonData.map(entry => [
                entry.author_id,
                entry.id,
                entry.entry_subject,
                entry.entry_text,
                entry.entry_type,
                entry.contact,
                entry.contact_date,
                entry.is_pinned,
                entry.submission_date,
                entry.last_update
            ]);
    
            temp.unshift(['author_id', 'entry_id', 'subject', 'body', 'type', 'contact(?)', 'contact_date', 'is_pinned', 'submission_date', 'last_update']);

            setLogEntriesCSV([...temp]);
            setSelectedInstructor(user[1]);
        }
        catch
        {

        }
      }

      

      const logEntryCSVData = async(user) => {
        return [];
      }

      const getCourseCSVData = async() => {
        try {
            const response = await fetch("/api/courses", {
                method: 'GET',
                credentials: 'include'
            });
            const jsonData = await response.json();

            var temp = jsonData.map(course => [
                course.id,
                course.course_name,
                course.student_total,
                course.average_grade,
                course.avg_attendance,
                course.pass_rate
            ]);
    
            temp.unshift(['courseID', 'name', 'student_count', 'average_grade', 'average_attendance', 'pass_rate']);

            setCourseCSVData(temp);
        }
        catch{
            console.log("Course CSV data failed to fetch");
        }
      }
      const getStudnentCSVData = async() =>{
        // setCsvRepData(data.map(student => [
        //     student.user_id,
        //     student.name,
        //     student.missing_assignments,
        //     100 - (student.student_participation * 100),
        //     student.trend
        // ]));
        const response = await fetch("/api/students/school/all", {
            method: 'GET',
            credentials: 'include'
        });
        const jsonData = await response.json();

        var temp = jsonData.map(student => [
            student.user_id,
            student.name,
            student.missing_assignments,
            100 - (student.student_participation * 100),
            student.trend
        ]);

        temp.unshift(['userID', 'name', 'missing_assignments', 'risk percent', 'trend']);

        setStudentCSVData([...temp]);
        
    }

    const handleClose = () =>{
        setShowManageTagModal(false);
    }




    useEffect(() => {
        getInstructors();
        //getInstructorCourses();
        getInstructorUpdates();
        getPreviousReports();
        getWithdrawnStudents();
    }, []);
    
    

    return (
            <Fragment>
                <ReactTooltip />
                {buildCourseSettingsModal()}
                {buildOnDemandReportsModal()}
                {buildWithdrawnStudentLogsModal()}
                {showManageTagModal&&
                <ManageTagModal inputRef={handleClose} props={{show: showManageTagModal}}></ManageTagModal>
                }
                
                <div className="header-bar-instruct">
                    <input className="search-bar" type="text" onChange={editSearchTerm} value={searchTerm.value} placeholder='Search by name...'/>
                </div>
                <button id="manage-tags" onClick={() => setShowManageTagModal(true)}>Manage Log Tags</button>
                <div className="list-container">
                <div className="instructor sort">
                <div className="page-csv-download"><FontAwesomeIcon icon={faChartBar} onClick={openModal} data-tip={"Reporting Panel"} style={{ color: "black" }}></FontAwesomeIcon></div>
                                <div className="person">
                                <div className="profile-pic">
                                    </div>
                                    <div className="name" onClick={(e) => sortClick("name")}>
                                        Instructor
                                        {nameSort?
                                        <FontAwesomeIcon icon={faArrowDown}/>
                                        :
                                        <FontAwesomeIcon icon={faArrowUp}/>}
                                    </div>
                                </div>
                                    <div className="course-count" onClick={(e) => sortClick("course-count")}>
                                        Course Count
                                        {courseCountSort?
                                        <FontAwesomeIcon icon={faArrowDown}/>
                                        :<FontAwesomeIcon icon={faArrowUp}/>
                                        }
                                </div>
                                <div className="ungraded-assignments" onClick={(e) => sortClick("ungraded-count")} data-tip="Number of ungraded assignments on the instructors ToDo list">
                                    Ungraded Submissions
                                    {ungradedSort?
                                    <FontAwesomeIcon icon={faArrowDown}/>
                                    :<FontAwesomeIcon icon={faArrowUp}/>
                                    }
                                </div>
                                <div className="updates-per-week" onClick={(e) => sortClick("updates")} data-tip="Average content updates per course last week">
                                    Course Updates
                                    {updatesSort?
                                    <FontAwesomeIcon icon={faArrowDown}/>
                                    :<FontAwesomeIcon icon={faArrowUp}/>
                                    }
                                </div>
                                <div className="updates-per-week" onClick={(e) => sortClick("messages")} data-tip="Canvas conversations last week">
                                    Canvas Conversations
                                    {messagesSort?
                                    <FontAwesomeIcon icon={faArrowDown}/>
                                    :<FontAwesomeIcon icon={faArrowUp}/>
                                    }
                                </div>
                                <div className="updates-per-week" onClick={(e) => sortClick("entries")} data-tip="Log entries created">
                                    Log Entries
                                    {entriesSort?
                                    <FontAwesomeIcon icon={faArrowDown}/>
                                    :<FontAwesomeIcon icon={faArrowUp}/>
                                    }
                                </div>
                                {/* <div className="participation-container" onClick={(e) => sortClick("participation")}>
                                    Participation
                                    <FontAwesomeIcon icon={faArrowsAltV}/>
                                </div> */}
                            </div>
                    <div className="card-list">
                        <FlipMove>
                        {dynamicSearch()}
                        </FlipMove>
                            </div>
                         
                </div>
            </Fragment>
        )
    }

    export default Instructors;