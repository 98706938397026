import React, { Fragment, useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import { CSVDownloader, CSVReader } from 'react-papaparse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowDown, faArrowUp, faCog, faThumbtack, faCheckSquare, faTrash, faEdit, faSave, faUndoAlt, faDownload, faUpload, faFileCsv } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import Button from 'react-bootstrap/Button';

const UploadCSVModal = (props) =>{

    const [csvFile, setCsvFile] = useState();
    const [showCSVModal, setShowCSVModal] = useState(props.props.show);
    const [csvErrors, setCsvErrors] = useState({});
    const [wasSubmitted, setWasSubmitted] = useState(false);

    const handleShowCSVModal = (e) =>{
        setShowCSVModal(true);
    }

    const handleCSVModalClose = (e) =>{
        setShowCSVModal(false);
        props.inputRef(false);
    }

    const handleOnDrop = (data) => {
        console.log('---------------------------')
        console.log(data)
        csvUploadCheck(data);
        setCsvFile(data);
        console.log('---------------------------')
      }
    
    const handleOnError = (err, file, inputElem, reason) => {
        console.log(err)
      }
    
    const handleOnRemoveFile = (data) => {
        console.log('---------------------------')
        console.log(data)
        console.log('---------------------------')
        setCsvErrors({});
      }

    const handleUploadFile = () =>{
        console.log('UPLOAD---------------------------')
        console.log(csvFile)
        console.log('---------------------------')
        uploadEntriesCSV(csvFile);
    }

    const getCSVErrors = (errors) =>
    {
        console.log(errors);
        
        try{
        let filtered = errors.errors.filter(function(e){
            return (e.student || e.type || e.contact || e.contactDate || e.entry || e.subject)
        });
        return filtered;
    }
    catch(err)
    {
        return [];
    }
    }

    async function csvUploadCheck(data){
        try{
            
        var request = "/api/students/logentry/csvcheck";
        const rawResponse = await fetch(request, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        var jsonData = await rawResponse.json().then();
        setCsvErrors(jsonData);

        

        //getLogEntries(selectedStudents[0]);
        //handleLogShow();
        }catch(err)
        {
            console.log(err);
        }
    }

    async function uploadEntriesCSV(data)
    {
        try{
            var request = "/api/students/logentry/csvupload";
            const rawResponse = await fetch(request, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            var jsonData = await rawResponse.json().then();
            console.log(JSON.stringify(jsonData));
            setWasSubmitted(jsonData.success);
            //getLogEntries(selectedStudents[0]);
            //handleLogShow();
            }catch(err)
            {
                console.log(err);
            }
    }

    return(
        <Modal show={showCSVModal} onHide={handleCSVModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Student Log Upload</Modal.Title>
        </Modal.Header>
        <Modal.Body> <CSVReader
        onDrop={handleOnDrop}
        onError={handleOnError}
        addRemoveButton
        removeButtonColor='#659cef'
        onRemoveFile={handleOnRemoveFile}
        onUpload={handleUploadFile}
        disabled={wasSubmitted?true:false}
      >
        <span>Drop CSV file here or click to upload.</span>
      </CSVReader>
      {
          csvErrors.success?<div className="csv-success">Looks good!<br/>Entries: {csvErrors.errors.length}</div>:""
      }
      {
        wasSubmitted?<div className="csv-success" style={{fontWeight: "bolder"}}><br/>Upload success!</div>:""
      }
      {!csvErrors.headers & JSON.stringify(csvErrors) !== JSON.stringify({})?
         <div className="header-error">Unrecognized header format. Please use the provided template.</div>:""
        }
        <div>
            {getCSVErrors(csvErrors).length > 0?
            <div className="csv-error-count">Issues identified: {getCSVErrors(csvErrors).length}<br></br><CSVDownloader
            data={getCSVErrors(csvErrors)}
            type="button"
            filename={'dash_import_errors'}
            bom={true}
            style={{borderStyle: "solid", borderColor: "red", backgroundColor: "white", borderRadius: "3px"}}
          >
            
            Errors
            <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
              
              
          </CSVDownloader></div>
            :""}
        {/* {
            
            getCSVErrors(csvErrors).map(error => (
            <div className="csv-error-wrap"><div className="error-head">Row:{error.rowNum}</div>{error.student?<div className="csv-error">Email not recognized</div>:<div className="csv-success">StudentEmail</div>}{error.type?<div className="csv-error">Type not recognized</div>:<div className="csv-success">Type</div>}{error.entry?<div className="csv-error">Entry is blank</div>:<div className="csv-success">Entry</div>}{error.contact?<div className="csv-error">Contact(true/false) not recognized</div>:<div className="csv-success">Contact</div>}{error.contactDate?<div className="csv-error">Contact date not valid</div>:<div className="csv-success">ContactDate</div>}{error.pinned?<div className="csv-error">Pinned (true/false) not valid</div>:<div className="csv-success">Pinned</div>}</div>
            ))
        } */}
        </div>
        {
           //csvErrors.headers? <div>{JSON.stringify(csvErrors)}</div>: "" 
        }
      </Modal.Body>
        <Modal.Footer className="csv-modal-wrap">
            <div className="download-template-wrap">
            <CSVDownloader
        data={[
          {
            "StudentEmail": "testemail@canvas.com",
            "Type": "Public",
            "Subject": "This is the subject (cannot be empty)",
            "Entry": "Log entry text here (cannot be empty)",
            "Contact": "true",
            "ContactDate": "12-02-2020",
            "Pinned": "false"
          },
          {
            "StudentEmail": "testemail@canvas.com",
            "Type": "Internal",
            "Subject": "This is the subject (cannot be empty)",
            "Entry": "Log entry text here (cannot be empty)",
            "Contact": "false",
            "ContactDate": "",
            "Pinned": "false"
          }
        ]}
        type="button"
        filename={'dash_import_template'}
        bom={true}
      >
        
            <FontAwesomeIcon icon={faFileCsv}></FontAwesomeIcon>
          
          template
      </CSVDownloader>
          </div>
          <div className="upload-foot-wrap">
          <Button variant="secondary" onClick={handleCSVModalClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUploadFile} disabled={csvErrors.success && !wasSubmitted ?false:true}>
            Upload
          </Button>
          </div>
        </Modal.Footer>
      </Modal>
    )
}

export default UploadCSVModal;