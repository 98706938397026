import React from 'react';
import {Router, Route } from "react-router-dom";
import history from "./history";
import './App.css';


//components
import UserProvider from './context/UserProvider';
import AdminHome from './pages/AdminHome';
import TeacherHome from './pages/TeacherHome';
import StudentView from './pages/StudentView';


function App() {
  let cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)user_name\s*\=\s*([^;]*).*$)|^.*$/, "$1");
  if(cookieValue.length>0)
    return (
      <Router history={history({forceRefresh: true})}>
        <UserProvider>
          {/* <Route path="/" component={AdminHome}/> */}
          <Route path="/AdminHome" component={AdminHome}/>
          <Route path="/AdminHome/:selection" component={AdminHome}/>
          <Route path="/TeacherHome/:courseID" component={TeacherHome}/>
          <Route path="/StudentView/:courseID/:studentID" component={StudentView}/>
          {/* <Route path="/profile" component={Profile}/> */}
        </UserProvider>
        {/* <Route path="/" exact component={Login}/> */}
      </Router>
    );
  else
          return(
          <div>
                  <h1>404: Not Found</h1>
          </div>
  );
  }

export default App;
