import React, { Fragment, useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTrash} from '@fortawesome/free-solid-svg-icons';

const ManageTagModal = (props) =>{

    const [show, setShow] = useState(props.props.show);
    const [addLogValue, setAddLogValue] = useState("");
    const [tags, setTags] = useState([]);
    const alternatingColor = ['#ffffff', '#dbdbdb'];

    const handleShow = () => setShow(true);

    const handleClose = () => {
        setShow(false);
        props.inputRef(false);
    }

    const editAddLogValue = (input) => {
        if(input != undefined)
            if(input.length < 31)
                setAddLogValue(input);
        //alert(input.value);
    }

    async function addTag(value){
        try {
            var request = "/api/logentry/tags/add";
            const rawResponse = await fetch(request, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({tag: addLogValue})
            }).then(response => response.json())
            .then(data => {
                if (data.success)
                {
                    getTags();
                    setAddLogValue("");
                }
                else
                    alert("An issue occured adding the tag. Please submit a help ticket to the SYS education help desk.");
            })
        //check for success or error
        }
        catch(err){
            console.log("Error creating tag: " + err);
        }
    }

    async function deleteTag(tag, id){
        if(confirm("Are you sure you want to delete the tag: " + "'" + tag + "'" + "?"))
        try {
            var request = "/api/logentry/tags/delete";
            const rawResponse = await fetch(request, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({tagID: id})
            }).then(response => response.json())
            .then(data => {
                if (data.success)
                {
                    getTags();
                    setAddLogValue("");
                }
                else
                    alert("An issue occured deleting the tag. Please submit a help ticket to the SYS education help desk.");
            })
        //check for success or error
        }
        catch(err){
            console.log("Error deleting tag: " + err);
        }
    }

    async function getTags(){
        try {
            //console.log(props.props.match.params.courseID);
            var response_string = "/api/logentry/tags/custom";
            //console.log(JSON.stringify(props.match.params.courseID));
            const response = await fetch(response_string);
            const jsonData = await response.json();
            //console.log("STUDENTS: " + JSON.stringify(jsonData));
            setTags(jsonData);
        }
        catch(err)
        {
            console.log("Error getting students in NewEntryModal.");
        }
    }

    const getDisabled = () =>{
        if(addLogValue != undefined)
            if(addLogValue.length > 2)
                return false;
        return true;
    }
    

    useEffect(() => {
        editAddLogValue();
        getTags();   
    }, []);

    return (
    <Modal show={show} onHide={handleClose} className="manage-tag-modal">
        <Modal.Header closeButton>
          <Modal.Title>Manage Log Entry Tags</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please note that removing or adding tags on this menu will affect the tags available for all teachers and admins.</Modal.Body>
        <div className="add-log-input"><input type="text" placeholder="Enter new log entry tag here..." value={addLogValue} onChange={(e) => editAddLogValue(e.target.value)}/>
        <button className="btn-primary" id="log-entry" onClick={ () => addTag()} disabled={getDisabled()}>Add Tag</button>
        </div>
        <div className="tag-container">
        {
            tags.map((tag, index) =>(
                <div className='tag' style={{backgroundColor:alternatingColor[index % alternatingColor.length]}} key={tag.id}>{tag.tag} <FontAwesomeIcon className="delete-icon" onClick={() => deleteTag(tag.tag, tag.id) }  icon={faTrash}/></div>
            ))
        }
        </div>
      </Modal>
    )
}

export default ManageTagModal;