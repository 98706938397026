import React, { Fragment, useState, useEffect } from "react";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faExclamationTriangle, faArrowUp, faInfoCircle, faFlag, faCog, faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import FlipMove from 'react-flip-move';
import Modal from 'react-bootstrap/Modal';
import { CSVLink, CSVDownload } from "react-csv";

const Courses = (props) => {
    console.log(props);
    var paramSearch = '';
    try {
        if (props.children.history.location.state.search.length > 0) {
            paramSearch = props.children.history.location.state.search;
            console.log("Search param passed in: " + paramSearch);
        }
        else {
            console.log("Else: ");
        }
    }
    catch (err) {
        //No search prams
        console.log("No params");
    }
    const [courses, setCourses] = useState([]);
    const [searchTerm, setSearchTerm] = useState('' + paramSearch);
    const [countSort, setCountSort] = useState(false);
    const [nameSort, setNameSort] = useState(false);
    const [gradeSort, setGradeSort] = useState(false);
    const [attendanceSort, setAttendanceSort] = useState(false);
    const [passSort, setPassSort] = useState(false);
    const [lastSort, setLastSort] = useState("");
    const [modalIsOpen, setIsOpen] = useState(false);
    const [nonAcaDisplay, setNonAcaDisplay] = useState(false);
    const [csvRepData, setCsvRepData] = useState([]);


    const history = useHistory();

    //var server_string = "https://a0cfe9a22b3c.ngrok.io";

    const sortClick = (type) => {
        var obj = [...courses];
        if (type === "student-count") {
            if (countSort) {
                obj.sort(function (a, b) {
                    return a.props.count - b.props.count;
                });
            }
            else {
                obj.sort(function (a, b) {
                    return b.props.count - a.props.count;
                });
            }
            setLastSort(type);
            setCountSort(!countSort);
        }
        else if (type === "avg-grade") {
            if (gradeSort) {
                obj.sort(function (a, b) {
                    if(a.props.grade == 'NaN' && b.props.grade != 'NaN')
                        return 1;
                    if(a.props.grade != 'NaN' && b.props.grade == 'NaN')
                        return -1;
                    if(a.props.grade == 'NaN' && b.props.grade == 'NaN')
                        return 0;
                    return parseInt(a.props.grade) - parseInt(b.props.grade);
                });
            }
            else {
                obj.sort(function (a, b) {
                    if(a.props.grade == 'NaN')
                        return 1;
                    return parseInt(b.props.grade) - parseInt(a.props.grade);
                });
            }
            setGradeSort(!gradeSort);
        }
        else if (type === "attendance") {
            if (attendanceSort) {
                obj.sort(function (a, b) {
                    return a.props.attendance - b.props.attendance;
                });
            }
            else {
                obj.sort(function (a, b) {
                    return b.props.attendance - a.props.attendance;
                });
            }
            setAttendanceSort(!attendanceSort);
        }
        else if (type === "passrate") {
            if (passSort) {
                obj.sort(function (a, b) {
                    return a.props.pass - b.props.pass;
                });
            }
            else {
                obj.sort(function (a, b) {
                    return b.props.pass - a.props.pass;
                });
            }
            setPassSort(!passSort);
        }
        else if (type === "name") {
            if (nameSort) {
                obj.sort(function (a, b) {
                    return a.props.name.toLowerCase().localeCompare(b.props.name.toLowerCase());
                });
            }
            else {
                obj.sort(function (a, b) {
                    return b.props.name.toLowerCase().localeCompare(a.props.name.toLowerCase());
                });
            }
            setNameSort(!nameSort);
        }
        setCourses(obj);
    }
    const toggleNonAcaDisplay = (toggle) =>{
        
        console.log("Toggle display is: " + toggle);
        setNonAcaDisplay(toggle);
        
    }

    const aggregateSelector = async (courseID, event, elem) => {
        console.log(elem);
        console.log("Aggregate selector toggled for " + courseID + " | " + event.target.checked);
        try {
            var request = "/api/courses/aggregatetoggle";
            const rawResponse = await fetch(request, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id: courseID, toggle: event.target.checked })
            }).then(response => response.json())
            getCourses();

        }
        catch
        {

        }
    }

    const buildCSVDownload = (data) =>{
        // setCsvRepData(data.map(student => [
        //     student.user_id,
        //     student.name,
        //     student.missing_assignments,
        //     100 - (student.student_participation * 100),
        //     student.trend
        // ]));

        var temp = data.map(course => [
            course.id,
            course.course_name,
            course.student_total,
            course.average_grade,
            course.avg_attendance,
            course.pass_rate
        ]);

        temp.unshift(['courseID', 'name', 'student_count', 'average_grade', 'average_attendance', 'pass_rate']);

        setCsvRepData([...temp]);
        
    }

    const getCourses = async () => {
        console.log("Getting courses");
        try {
            const response = await fetch("/api/courses", {
                method: 'GET',
                credentials: 'include'
            });
            const jsonData = await response.json();
            setCourses(jsonData.map(course => (
                <div className="course" courseid={course.id} key={course.id} name={course.course_name} count={course.student_total} grade={course.average_grade} attendance={course.avg_attendance} pass={course.pass_rate} instid={course.user_id}>
                    {/* <ReactTooltip multiline={true} /> */}
                    {nonAcaDisplay==true &&
                        <div className="aggregate-toggle"><input className="toggle-box" type="checkbox" checked={course.nonacademic} onChange={(event) => aggregateSelector(course.id, event, this)}></input></div>}
                    <div className="course-name" onClick={() => routeChange(course.id)}>
                        {course.course_name}
                    </div>
                    <div className="student-count">

                        <div className="student-count-text">
                            {course.student_total}
                        </div>
                    </div>
                    <div className="avg-grade">

                        <div className="student-count-text">
                            {course.average_grade != null && course.average_grade != "NaN"?
                            <div>{course.average_grade + "%"}</div>
                            :<div>{<center><FontAwesomeIcon icon={faExclamationTriangle} data-tip="No grade data found"></FontAwesomeIcon></center>}</div>}
                        </div>
                    </div>

                    <div className="participation-container">
                        {course.avg_attendance > 0 ?
                            <div className="participation-bar">
                                {course.avg_attendance * 100 > 75 &&
                                    <ProgressBar variant="success" now={course.avg_attendance * 100} label={Math.round(course.avg_attendance * 100) + "%"} />
                                }
                                {course.avg_attendance * 100 <= 75 && course.avg_attendance * 100 >= 60 &&
                                    <ProgressBar variant="warning" now={course.avg_attendance * 100} label={Math.round(course.avg_attendance * 100) + "%"} />
                                }
                                {course.avg_attendance * 100 < 60 &&
                                    <ProgressBar variant="danger" now={course.avg_attendance * 100} label={Math.round(course.avg_attendance * 100) + "%"} />
                                }
                            </div>
                            : <div className="participation-bar"><center><FontAwesomeIcon icon={faExclamationTriangle} data-tip="No attendance data found"></FontAwesomeIcon></center></div>}
                    </div>
                    <div className="participation-container">
                        <div className="participation-bar">
                            {course.pass_rate > 75 &&
                                <ProgressBar variant="success" now={course.pass_rate} label={Math.round(course.pass_rate) + "%"} />
                            }
                            {course.pass_rate <= 75 && course.pass_rate > 60 &&
                                <ProgressBar variant="warning" now={course.pass_rate} label={Math.round(course.pass_rate) + "%"} />
                            }
                            {course.pass_rate <= 60 &&
                                <ProgressBar variant="danger" now={course.pass_rate} label={Math.round(course.pass_rate) + "%"} />
                            }
                        </div>
                    </div>

                </div>
            )));
            buildCSVDownload(jsonData);
        } catch (err) {
            console.error(err.message);
        }
        
    }

    // const getInstructorCourses = async() => {
    //     try {
    //         const response = await fetch("http://localhost:5000/instructorcourses");
    //         const jsonData = await response.json();

    //         console.log(jsonData);
    //         setInstructorcourses(jsonData);
    //     } catch (err) {
    //         console.error(err.message);
    //     }
    // }
    const editSearchTerm = (e) => {

        setSearchTerm(e.target.value);

    }

    const dynamicSearch = () => {
        //console.log("Course id: " + course.id);
        console.log(searchTerm);
        return courses.filter(course => (course.props.name.toLowerCase().includes(searchTerm.toLowerCase()) || course.props.instid == parseInt(searchTerm)));
    }

    const routeChange = (courseID) => {
        let path = `/TeacherHome/` + courseID;
        history.push(path);
    }

    const openModal = () => {
        console.log("Modal open");
        setIsOpen(true);
      }

      const afterOpenModal = () => {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
      }
    
      const closeModal = () => {
        setIsOpen(false);
        getCourses();
      }

      

      const buildCourseSettingsModal = () => {
          return (
            <Modal
            show={modalIsOpen}
            onHide={closeModal}
        >
            <Modal.Header>Course Display Settings</Modal.Header>
            <Modal.Body>
            <div className="non-academic-toggle"><label for="nonAcaDisplay">Enable Academic Indicators</label><input name="nonAcaDisplay" type="checkbox" checked={nonAcaDisplay} onChange={(event)=>toggleNonAcaDisplay(event.target.checked)}></input></div>
            
            </Modal.Body>
            <Modal.Footer>Warning! Altering a courses academic status may cause unexpected issues. Non-academic course data is updated differently than academic courses. Only change these if you understand the outcome.<button className="btn btn-sml btn-primary" onClick={closeModal}>Close to view changes</button></Modal.Footer>
        </Modal>
          );
      }

    useEffect(() => {
        getCourses();
    }, []);


    return (
        <Fragment>
            {buildCourseSettingsModal()}
            <div className="header-bar-instruct">
                <input className="search-bar" type="text" onChange={editSearchTerm} defaultValue={searchTerm} value={searchTerm.value} placeholder='Search by course name...' />
            </div>
            <div className="list-container">
            <ReactTooltip multiline={true} />
                <div className="card-list">
                    <div className="course sort">
                        {nonAcaDisplay&&
                            <div className="aggregate-toggle">Non-Academic Course</div>}
                        <div className="course-settings-selector"><FontAwesomeIcon icon={faCog} onClick={openModal} data-tip={"Course display settings"} /><div className="page-csv-download"><CSVLink filename={new Date().toDateString() + "courses-report.csv"} data={csvRepData}><FontAwesomeIcon icon={faFileCsv} data-tip={"Download contents of this page in CSV format"} style={{ color: "black" }}></FontAwesomeIcon></CSVLink></div></div>
                        
                        <div className="course-name" onClick={(e) => sortClick("name")}>
                            Course
                        {nameSort ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />}
                        </div>
                        <div className="student-count" onClick={(e) => sortClick("student-count")}>
                            <div>Students</div>
                            {countSort ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />}

                        </div>
                        <div className="avg-grade" onClick={(e) => sortClick("avg-grade")}>
                            <div>Avg Grade</div>
                            {gradeSort ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />}

                        </div>

                        <div className="participation-container">
                            <div className="bar-title" onClick={(e) => sortClick("attendance")}>Attendance{attendanceSort ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />}<div className="info-icon"><FontAwesomeIcon icon={faInfoCircle} data-tip={"For accurate attendance tracking: <br /> -Zoom topic must match course name <br /> OR <br /> -Zoom topic must contain canvas course ID"}></FontAwesomeIcon></div></div>

                        </div>
                        <div className="participation-container">
                            <div className="bar-title" onClick={(e) => sortClick("passrate")}>Pass Rate{passSort ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />}</div>
                        </div>
                    </div>

                    <FlipMove>
                        {dynamicSearch()}
                    </FlipMove>

                    {/* {courses.map(course => (
                            <div className="course">
                                <div className="course-name">
                                    {course.course_name}
                                </div>
                                <div className="student-count">
                                    Student Count<br/>
                                    <div className="student-count-text">
                                        {course.student_total}
                                    </div>
                                </div>
                                <div className="avg-grade">
                                    Average Grade<br/>
                                    <div className="student-count-text">
                                        {course.average_grade}
                                    </div>
                                </div>
                                <div className="participation-container">
                                    Participation:
                                    <div className="participation-bar">
                                        
                                    </div>
                                </div>
                                <div className="passrate-container">
                                    Pass Rate:
                                    <div className="passrate-bar">
                                    {course.pass_rate > 75 &&
                                    <ProgressBar striped animated variant="success" now={course.pass_rate} />
                                    }
                                    {course.pass_rate < 75 && course.pass_rate > 60 &&
                                    <ProgressBar striped animated variant="warning" now={course.pass_rate} />
                                    }
                                    {course.pass_rate < 60 &&
                                    <ProgressBar striped animated variant="danger" now={course.pass_rate} />
                                    }
                                    </div>
                                </div>
                            </div>
                        ))} */}
                </div>
            </div>
        </Fragment>
    )
}

export default Courses;