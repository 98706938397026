import React, { Fragment, useState, useEffect } from "react";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { CSVDownloader, CSVReader } from 'react-papaparse';
import FlipMove from 'react-flip-move';
import { CSVLink, CSVDownload } from "react-csv";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faExclamationTriangle, faArrowDown, faArrowUp, faInfoCircle, faCog, faThumbtack, faCheckSquare, faTrash, faEdit, faSave, faUndoAlt, faDownload, faUpload, faFileCsv, faSync } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import { useHistory } from "react-router-dom";
import NewEntryModal from "./NewEntryModal";


const Students = (props) => {
    const history = useHistory();
    const [students, setStudents] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [nameSort, setNameSort] = useState(false);
    const [missingAssignmentSort, setMissingAssignmentSort] = useState(false);
    const [riskSort, setRiskSort] = useState(false);
    const [trendSort, setTrendSort] = useState(false);
    const [courseInfo, setCourseInfo] = useState({});
    //const [courseAttendance, setCourseAttendance] = useState({});
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [show, setShow] = useState(false);
    const [showLog, setShowLog] = useState(false);
    const [entryText, setEntryText] = useState("");
    const [entryType, setEntryType] = useState("internal");
    const [isContact, setIsContact] = useState(false);
    const [isPinned, setIsPinned] = useState(false);
    const [contactDate, setContactDate] = useState(new Date().toISOString().slice(0, 10));
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [wasSubmitted, setWasSubmitted] = useState(false);
    const [submittedAtDate, setSubmittedAtDate] = useState("");
    const [logEntries, setLogEntries] = useState([]);
    const [avgsData, setAvgsData] = useState({views: {avg: "N/A"}, submissions: {avg: "N/A"}});
    const [attendanceData, setAttendanceData] = useState({});
    const [typeSort, setTypeSort] = useState(true);
    const [authSort, setAuthSort] = useState(true);
    const [submissionSort, setSubmissionSort] = useState(true);
    const [logSearchTerm, setLogSearchTerm] = useState("");
    const [showCSVModal, setShowCSVModal] = useState(false);
    const [csvErrors, setCsvErrors] = useState({});
    const [csvFile, setCsvFile] = useState({});
    const [csvRepData, setCsvRepData] = useState([]);
    const [lastUpdate, setLastUpdate] = useState([{last_update: 'none'}]);
    const [assignmentsLink, setAssignmentsLink] = useState('https://orca.instructure.com'); //<--- REPLACE IN PRODUCTION
    const [alphabetSelector, setAlphabetSelector] = useState();
    const [rosterSyncing, setRosterSyncing] = useState(false);
    //const [editingEntry, setEditingEntry] = useState();

    var editingEntry = {id: 0, text: "", type: "", contact: false, contactDate: new Date().toISOString().slice(0, 10), pinned: false}
    const newEditEntry = {id: 0, text: "", type: "", contact: false, contactDate: new Date().toISOString().slice(0, 10), pinned: false}
    
    const routeChange = (courseID, studentID) =>{
        if(courseID==1)
        {
           getCourseList(studentID);
           return;
        }
        
        let path = `/StudentView/` + courseID + '/' + studentID; 
        history.push(path);
    }

    const getRosterSyncClass = () =>
    {
        if(rosterSyncing)
            return "roster-sync active";
        else
            return "roster-sync";
    }

    async function getCourseList(studentID)
    {
        try {
            var response_string = "";
            response_string = "/api/students/courselist?id=" + parseInt(studentID);
            const response = await fetch(response_string);
            const jsonData = await response.json();
            var courseID = jsonData.course_id;
            let path = `/StudentView/` + courseID + '/' + studentID; 
            history.push(path);
            
        }
        catch (err) {
            console.log(err);
        }
    }

    const handleClose = () => {
        setEntryText("");
        setEntryType("Internal");
        setIsContact(false);
        setIsPinned(false);
        setContactDate(new Date().toISOString().slice(0, 10));
        setShow(false);
        setWasSubmitted(false);
        setSubmittedAtDate("");
    }
    const handleShow = () => {
        setEntryType("internal");
        setShow(true);
    }

    const handleLogClose = () =>{
        console.log("Closing log");
        editingEntry = JSON.parse(JSON.stringify(newEditEntry));
        setShowLog(false);
        
    }

    const handleLogShow = () =>{
        console.log("On show, editingEntry: " + JSON.stringify(editingEntry));
        getLogEntries(selectedStudents[0]);
        getLogEntries(selectedStudents[0]);
        setShowLog(true);
    }

    const getCookieValue = () => {
        let cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)user_name\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        return cookieValue;
    }

    const getCookieRole = () => {
        let cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)role\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        return cookieValue;
    }

    const sortClick = (type) => {
        var obj = [...students];
        if (type === "name") {
            if (nameSort) {
                obj.sort(function(a, b){
                    return a.props.sname.toLowerCase().localeCompare(b.props.sname.toLowerCase());
                });
            }
            else {
                obj.sort(function(a, b){
                    return b.props.sname.toLowerCase().localeCompare(a.props.sname.toLowerCase());
                });
            }
            setNameSort(!nameSort);
        }
        if (type === "missing-assignments") {
            if (missingAssignmentSort) {
                obj.sort(function(a, b){
                    if(a.props.missing == null)
                        return -1;
                    if(b.props.missing == null)
                        return 1;
                    return parseInt(a.props.missing) > parseInt(b.props.missing)?1:-1;
                });
            }
            else {
                obj.sort(function(a, b){
                    if(a.props.missing == null)
                        return 1;
                    if(b.props.missing == null)
                        return -1;
                    return parseInt(a.props.missing) > parseInt(b.props.missing)?-1:1;
                });
            }
            setMissingAssignmentSort(!missingAssignmentSort);
        }
        if (type === "risk") {
            if (riskSort) {
                obj.sort(function(a, b){
                    return a.props.risk > b.props.risk?1:-1;
                });
            }
            else {
                obj.sort(function(a, b){
                    return a.props.risk > b.props.risk?-1:1;
                });
            }
            setRiskSort(!riskSort);
        }
        if (type === "trend") {
            if (trendSort) {
                obj.sort(function(a, b){
                    return a.props.trend > b.props.trend?1:-1;
                });
            }
            else {
                obj.sort(function(a, b){
                    return a.props.trend > b.props.trend?-1:1;
                });
            }
            setTrendSort(!trendSort);
        }
        setStudents(obj);
    }

    async function getCourseInfo() {
        try {
            var response_string = "";
            response_string = "/api/course/info?id=" + parseInt(props.props.match.params.courseID);
            const response = await fetch(response_string);
            const jsonData = await response.json();
            setCourseInfo(jsonData.info);
            console.log(jsonData.info);
        }
        catch (err) {
            console.log(err);
        }
    }

    async function getLastUpdate(){
        try {
            var response_string = "";
            response_string = "/api/course/lastupdate?id=" + parseInt(props.props.match.params.courseID);
            const response = await fetch(response_string);
            const jsonData = await response.json();
            console.log(JSON.stringify(jsonData));
            setLastUpdate(jsonData);
        }
        catch (err) {
            console.log(err);
        }
    }

    function findAttendance(attendance, student) {
        try {
            var attended = attendance.find(obj => obj.login_id == student);
            return attended.attended;
        }
        catch{
            return 0;
        }

    }

    function findLastAttended(attendance, student) {
        try {
            var attended = attendance.find(obj => obj.login_id == student);
            return attended.last_attended;
        }
        catch{
            return "N/A";
        }
    }

    function removeStudentDropdown(student, elem) {
        var copy = [...selectedStudents];
        for(var i = 0; i < copy.length; i++)
        {
            //console.log("Searching for student: " + JSON.stringify(student));
            if(selectedStudents[i].studentID === student.studentID)
            {
                //console.log("Found student at index: " + i);
                var spliced = selectedStudents.splice(i, 1);
                //elem.classList.remove("selected");
                setSelectedStudents([...selectedStudents]);
                //console.log("Removed student: " + JSON.stringify(spliced));
                getStudents(props);
                return true;
            }
        }
        //console.log("I made it far");
        return false;
    }

    const studentSelect = (student, elem) =>
    {
        var studentElem = elem.target.parentNode.parentNode.parentNode;
        var selectedStudent = {studentName: student.name, studentID: student.user_id};
        if(!removeStudentDropdown(selectedStudent, studentElem))
        {
            selectedStudents.push(selectedStudent);
            setSelectedStudents([...selectedStudents]);
        }
        else   
            return;
        
        getStudents(props);
        console.log(selectedStudents);
    }

    function isSelected(student)
    {
        for(var i = 0; i < selectedStudents.length; i++)
        {
            if(selectedStudents[i].studentID === student.user_id)
            {
                //console.log("isSelected ran.");
                return true;
            }
        }
        return false
    }

    async function getAvgsData(props)
    {
        if(props.props.match.params.courseID != 'school')
        {
            const avgsResponse = await fetch("/api/course/averages?id=" + props.props.match.params.courseID);
            const avgsData = await avgsResponse.json();
            console.log(avgsData);
            setAvgsData(avgsData);
        }
    }

    async function getCourseAttendance(props)
    {
        console.log(props);
        if(props.props.match.params.courseID != 'school')
        {
            const attendanceResponse = await fetch("/api/course/attendance?id=" + parseInt(props.props.match.params.courseID));
            const classAttendance = await attendanceResponse.json();
            console.log(classAttendance);
            setAttendanceData(classAttendance);
        }
    }
    
    async function setSchoolStudents(char)
    {
        console.log("Setting school students for: " + char);
        if(!char)
            char = 'a';
        const response = await fetch("/api/students/school?ch=" + char);
        const jsonData = await response.json();
        jsonData.sort((a, b) => (a.name > b.name) ? 1 : -1);
        if(char.length == 1)
            setAlphabetSelector(char);
        else
            setAlphabetSelector(0);
        //console.log(JSON.stringify(jsonData[0].student_participation));

        setCourseInfo({name: "School-wide"});

        setStudents(jsonData.map(student => (
            <div className={isSelected(student) ? "student selected" : "student"} key={student.user_id} userid={student.user_id} sname={student.name} missing={student.missing_assignments} risk={student.student_participation} trend={student.trend}>
                    <div className="person">
                        <div className="profile-pic">
                            <img src={student.avatar_url}></img>
                        </div>
                        <div className="name" onClick={() => routeChange(1, student.user_id)}>
                            {student.name}
                        </div>
                    </div>
                    <div className="missing-assignments">
                        {student.missing_assignments}
                    </div>
                    <div className="participation-container">
                        <div className="participation-bar">
                            {student.student_participation >= 0?
                            <div>
                            {100 - (student.student_participation * 100) <= 50 &&
                                <ProgressBar  variant="success" now={100 - (student.student_participation * 100)} />
                            }

                            {100 - (student.student_participation * 100) <= 75 && 100 - (student.student_participation * 100) > 50 &&
                                <ProgressBar  variant="warning" now={100 - (student.student_participation * 100)} />
                            }

                            {100 - (student.student_participation * 100) > 75 &&
                                <ProgressBar  variant="danger" now={100 - (student.student_participation * 100)} />
                            }
                            </div>:<center><FontAwesomeIcon icon={faExclamationTriangle} data-tip={"Not enough data"}></FontAwesomeIcon>  Incomplete data</center>}
                        </div>
                    </div>
                    <div className="trend-container">
                        
                        <div className="trend-arrow">
                            {(student.trend >= -.1 && student.trend <= .1) &&
                                <FontAwesomeIcon icon={faArrowRight} data-tip="Students risk was netural over the last week." />
                            }

                            {student.trend > .1 &&
                                <FontAwesomeIcon icon={faArrowDown} style={{ color: "#04d10e" }} data-tip="Students risk decreased over the last week." />
                            }

                            {student.trend < -.1 &&
                                <FontAwesomeIcon icon={faArrowUp} style={{ color: "#d10415" }} data-tip="Students risk increased over the last week." />
                            }
                        </div>
                    </div>
                </div>)));
        buildCSVDownload(jsonData);
    }


    async function getStudents(props, type, char) {
        try {
            console.log(props.props.match.params.courseID + ", " + type + ", " + char );
            if(props.props.match.params.courseID === 'school')
            {
                console.log("Looking for students: " + props.props.match.params.courseID + "char: " + char);
                setSchoolStudents(char);
                return;
            }
            
            console.log("Getting students start: " + new Date().getMinutes() + "|" + new Date().getSeconds());
            var response_string = "";
            var courseID = props.props.match.params.courseID;
            if (type) {
                response_string = "/api/students?courseID=" + props.props.match.params.courseID + "&sort_type=" + type;
            }
            else
                response_string = "/api/students?courseID=" + props.props.match.params.courseID;
            //console.log(JSON.stringify(props.match.params.courseID));
            const response = await fetch(response_string);
            const jsonData = await response.json();
            jsonData.sort((a, b) => (a.name > b.name) ? 1 : -1);

            console.log(attendanceData);
            const attendanceResponse = await fetch("/api/course/attendance?id=" + parseInt(props.props.match.params.courseID));
            const classAttendance = await attendanceResponse.json();
            console.log(classAttendance);
            setAttendanceData(classAttendance);

            const avgsResponse = await fetch("/api/course/averages?id=" + props.props.match.params.courseID);
            const avgsData = await avgsResponse.json();
            console.log(avgsData);
            setAvgsData(avgsData);

            let info_string = "/api/course/info?id=" + parseInt(props.props.match.params.courseID);
            const info = await fetch(info_string);
            const courseInfo = await info.json();
            

            setStudents(jsonData.map(student => (
                <div className={isSelected(student) ? "student selected" : "student"} key={student.user_id} userid={student.user_id} sname={student.name} missing={student.missing_assignments} risk={student.student_participation} trend={student.trend}>
                    <div className="person">
                        <div className="profile-pic">
                            <img src={student.avatar_url}></img>
                        </div>
                        <div className="name" onClick={() => routeChange(courseID, student.user_id)}>
                            {student.name}
                        </div>
                    </div>
                    <div className="missing-assignments">
                        <div onClick={()=> window.open(assignmentsLink + "/courses/" + props.props.match.params.courseID + "/grades/" + student.user_id, "_blank")}>{student.missing_assignments}</div>
                    </div>
                    <div className="participation-container">
                    {courseInfo.info.nonacademic != true &&
                        <div className="inspect">
                            <div className="inspect-wrapper">
                                
                                <div className="inspect-elem">
                                    <div className="inspect-elem-title" data-tip="Student's average daily course views">Views</div>
                                    <ProgressBar variant="info" now={parseFloat(student.views_avg).toFixed(2)} max={parseFloat(avgsData.views.avg).toFixed(2)} />
                                </div>
                                <div className="inspect-elem">
                                    <center><div className="inspect-elem-title" data-tip="Student's average daily course submissions">Submissions</div></center>
                                    <ProgressBar variant="info" now={parseFloat(student.submissions_avg).toFixed(2)} max={parseFloat(avgsData.submissions.avg).toFixed(2)} />

                                </div>
                                <div className="inspect-elem">
                                    <center><div className="inspect-elem-title">Attendance</div></center>
                                    <ProgressBar variant="info" label={findAttendance(classAttendance.attendance, student.login_id) + "/" + parseInt(courseInfo.info.held_classes)} now={findAttendance(classAttendance.attendance, student.login_id)} max={parseInt(courseInfo.info.held_classes)} data-tip={"Last attended: " + findLastAttended(classAttendance.attendance, student.login_id)} />

                                </div>
                            </div>
                        </div>}

                        

                        <div className="participation-bar">
                            {student.student_participation >= 0?
                            <div>
                            {100 - (student.student_participation * 100) <= 50 &&
                                <ProgressBar  variant="success" now={100 - (student.student_participation * 100)} />
                            }

                            {100 - (student.student_participation * 100) <= 75 && 100 - (student.student_participation * 100) > 50 &&
                                <ProgressBar  variant="warning" now={100 - (student.student_participation * 100)} />
                            }

                            {100 - (student.student_participation * 100) > 75 &&
                                <ProgressBar  variant="danger" now={100 - (student.student_participation * 100)} />
                            }
                            </div>:<center><FontAwesomeIcon icon={faExclamationTriangle} data-tip={"Not enough data"}></FontAwesomeIcon>  Incomplete data</center>}
                        </div>
                    </div>
                    <div className="trend-container">
                        
                        <div className="trend-arrow">
                        {(student.trend >= -.1 && student.trend <= .1) &&
                                <FontAwesomeIcon icon={faArrowRight} data-tip="Students risk was netural over the last week." />
                            }

                            {student.trend > .1 &&
                                <FontAwesomeIcon icon={faArrowDown} style={{ color: "#04d10e" }} data-tip="Students risk decreased over the last week." />
                            }

                            {student.trend < -.1 &&
                                <FontAwesomeIcon icon={faArrowUp} style={{ color: "#d10415" }} data-tip="Students risk increased over the last week." />
                            }
                        </div>
                    </div>
                </div>
            )));
            
            buildCSVDownload(jsonData);
            console.log("Getting students stop: " + new Date().getMinutes() + "|" + new Date().getSeconds());
                            
        } catch (err) {
            console.error(err.message);
        }
        
    }

    const buildCSVDownload = (data) =>{
        // setCsvRepData(data.map(student => [
        //     student.user_id,
        //     student.name,
        //     student.missing_assignments,
        //     100 - (student.student_participation * 100),
        //     student.trend
        // ]));

        var temp = data.map(student => [
            student.user_id,
            student.name,
            student.missing_assignments,
            100 - (student.student_participation * 100),
            student.trend
        ]);

        temp.unshift(['userID', 'name', 'missing_assignments', 'risk percent', 'trend']);

        setCsvRepData([...temp]);
        
    }

    const handleCSVModalClose = (e) =>{
        setShowCSVModal(false);
    }

    const handleOnDrop = (data) => {
        console.log('---------------------------')
        console.log(data)
        csvUploadCheck(data);
        setCsvFile(data);
        console.log('---------------------------')
      }
    
    const handleOnError = (err, file, inputElem, reason) => {
        console.log(err)
      }
    
    const handleOnRemoveFile = (data) => {
        console.log('---------------------------')
        console.log(data)
        console.log('---------------------------')
        setCsvErrors({});
      }

    const handleUploadFile = () =>{
        console.log('UPLOAD---------------------------')
        console.log(csvFile)
        console.log('---------------------------')
        uploadEntriesCSV(csvFile);
    }

    const getCSVErrors = (errors) =>
    {
        //console.log(errors);
        
        try{
        let filtered = errors.errors.filter(function(e){
            return (e.student || e.type || e.contact || e.contactDate)
        });
        return filtered;
    }
    catch(err)
    {
        return [];
    }
    }

    async function csvUploadCheck(data){
        try{
            
        var request = "/api/students/logentry/csvcheck";
        const rawResponse = await fetch(request, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        var jsonData = await rawResponse.json().then();
        setCsvErrors(jsonData);

        }catch(err)
        {
            console.log(err);
        }
    }

    const getSearchStudents = (char) =>
    {
        
    }


    const editSearchTerm = (e) => {
        console.log("search term update");
        if(window.location.href.includes("school") && e.target.value.length > 2)
        {
            console.log("search term update searching db");
            setSearchTerm(e.target.value);
            getStudents(props, "something", e.target.value);
        }
        else
            if(e.target.value.length < 1)
                getStudents(props, "any", "a");
            setSearchTerm(e.target.value);
    }
    const dynamicSearch = () => {
                return students.filter(student => student.props.sname.toLowerCase().includes(searchTerm.toLowerCase()));
    }

    const buildAlphabetSelector = () => {
        var alphabet = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('');
        console.log(alphabetSelector);
        setAlphabetSelector(alphabet.map(char => {
            <div>{char}</div>
        }));
    }

    async function syncCourseRoster(courseID)
    {
        console.log("Sycning course roster for: " + JSON.stringify(courseID));
        var request = "/api/course/sync";
        const rawResponse = await fetch(request, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({courseID: courseID})
        });
        var jsonData = await rawResponse.json();
        console.log(JSON.stringify(jsonData));
        if(jsonData.status === "running")
        {
            setRosterSyncing(true);
            alert("Roster sync for course: " + courseID + " started. Please refresh the page in a few moments to view updated roster.");
        }
        else
        {
            setRosterSyncing(false);
            alert("This sync failed to start, either because a sync is already running for this course, or a number of other courses are already syncing. Please try again in a few minutes.");
        }
        
        return;
    }

    async function getSyncingCourses(courseID)
    {
        console.log("Checking Sycning courses: " + courseID);
        var request = "/api/course/sync/current";
        const rawResponse = await fetch(request, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        var jsonData = await rawResponse.json();
        console.log(jsonData.current.includes(courseID));
        console.log(jsonData.current.length);
        if(jsonData.current.includes(parseInt(courseID)) || jsonData.current.length > 2)
            setRosterSyncing(true);
        else
            setRosterSyncing(false);
    }

    useEffect(() => {
        getLastUpdate();
        getAvgsData(props);
        getCourseInfo();
        getCourseAttendance(props);
        getStudents(props);
        getSyncingCourses(props.props.match.params.courseID);
    }, []);

    return (
        <Fragment>
            <ReactTooltip></ReactTooltip>
            {show&&
            <NewEntryModal inputRef={handleClose} props={{show: show, studentInfo:"NONE", courseID: props.props.match.params.courseID}}></NewEntryModal>
            }
            <div className="header-bar-instruct">
                <ReactTooltip></ReactTooltip>
                <div className="course-title"> > {courseInfo.name} </div>
                {props.props.match.params.courseID != 'school'?
                <div className="log-entry-buttons">
                    <button id="log-entry" onClick={handleShow}>+ Add Log Entry</button>
                </div>
                :""}
                <input className="search-bar" type="text" onChange={editSearchTerm} value={searchTerm.value} placeholder='Search by name...' />
            </div>
            <div className="course-metrics">
                    <div className="course-title">{courseInfo.name}<div data-tip={"Non-academic courses display aggregate risk scores and total number of missing assignments"} className="nonacademic-indicator">{courseInfo.nonacademic &&"*Non-Academic*"}</div></div>
                    {courseInfo.average_grade&&
                    <div className="course-average-grade">Avg Grade: {courseInfo.average_grade}%</div>}
                    {courseInfo.average_grade&&
                    <div className="course-live-class-count">Live Classes Held: {courseInfo.held_classes > 0?courseInfo.held_classes:<FontAwesomeIcon data-tip={"No live classes found"} style={{color: "orange"}} icon={faExclamationTriangle}></FontAwesomeIcon>}</div>}
                    {(lastUpdate[0] && props.props.match.params.courseID != 'school')?
                <div className="last-course-update">Last Updated: {new Date(lastUpdate[lastUpdate.length-1].last_update).toString().split(':')[0]}:{new Date(lastUpdate[lastUpdate.length-1].last_update).toString().split(':')[1]}</div>:""
                }
                {props.props.match.params.courseID == 'school'&&
                <div className="alphabet-selector">
                    {alphabetSelector == 'a'?
                        <div className="selection selected" onClick={() => setSchoolStudents('a')}>A</div>:
                        <div className="selection" onClick={() => setSchoolStudents('a')}>A</div>
                    }
                    {alphabetSelector == 'b'?
                        <div className="selection selected" onClick={() => setSchoolStudents('b')}>B</div>:
                        <div className="selection" onClick={() => setSchoolStudents('b')}>B</div>
                    }
                    {alphabetSelector == 'c'?
                        <div className="selection selected" onClick={() => setSchoolStudents('c')}>C</div>:
                        <div className="selection" onClick={() => setSchoolStudents('c')}>C</div>
                    }
                    {alphabetSelector == 'd'?
                        <div className="selection selected" onClick={() => setSchoolStudents('d')}>D</div>:
                        <div className="selection" onClick={() => setSchoolStudents('d')}>D</div>
                    }
                    {alphabetSelector == 'e'?
                        <div className="selection selected" onClick={() => setSchoolStudents('e')}>E</div>:
                        <div className="selection" onClick={() => setSchoolStudents('e')}>E</div>
                    }
                    {alphabetSelector == 'f'?
                        <div className="selection selected" onClick={() => setSchoolStudents('f')}>F</div>:
                        <div className="selection" onClick={() => setSchoolStudents('f')}>F</div>
                    }
                    {alphabetSelector == 'g'?
                        <div className="selection selected" onClick={() => setSchoolStudents('g')}>G</div>:
                        <div className="selection" onClick={() => setSchoolStudents('g')}>G</div>
                    }
                    {alphabetSelector == 'h'?
                        <div className="selection selected" onClick={() => setSchoolStudents('h')}>H</div>:
                        <div className="selection" onClick={() => setSchoolStudents('h')}>H</div>
                    }
                    {alphabetSelector == 'i'?
                        <div className="selection selected" onClick={() => setSchoolStudents('i')}>I</div>:
                        <div className="selection" onClick={() => setSchoolStudents('i')}>I</div>
                    }
                    {alphabetSelector == 'j'?
                        <div className="selection selected" onClick={() => setSchoolStudents('j')}>J</div>:
                        <div className="selection" onClick={() => setSchoolStudents('j')}>J</div>
                    }
                    {alphabetSelector == 'k'?
                        <div className="selection selected" onClick={() => setSchoolStudents('k')}>K</div>:
                        <div className="selection" onClick={() => setSchoolStudents('k')}>K</div>
                    }
                    {alphabetSelector == 'l'?
                        <div className="selection selected" onClick={() => setSchoolStudents('l')}>L</div>:
                        <div className="selection" onClick={() => setSchoolStudents('l')}>L</div>
                    }
                    {alphabetSelector == 'm'?
                        <div className="selection selected" onClick={() => setSchoolStudents('m')}>M</div>:
                        <div className="selection" onClick={() => setSchoolStudents('m')}>M</div>
                    }
                    {alphabetSelector == 'n'?
                        <div className="selection selected" onClick={() => setSchoolStudents('n')}>N</div>:
                        <div className="selection" onClick={() => setSchoolStudents('n')}>N</div>
                    }
                    {alphabetSelector == 'o'?
                        <div className="selection selected" onClick={() => setSchoolStudents('o')}>O</div>:
                        <div className="selection" onClick={() => setSchoolStudents('o')}>O</div>
                    }
                    {alphabetSelector == 'p'?
                        <div className="selection selected" onClick={() => setSchoolStudents('p')}>P</div>:
                        <div className="selection" onClick={() => setSchoolStudents('p')}>P</div>
                    }
                    {alphabetSelector == 'q'?
                        <div className="selection selected" onClick={() => setSchoolStudents('q')}>Q</div>:
                        <div className="selection" onClick={() => setSchoolStudents('q')}>Q</div>
                    }
                    {alphabetSelector == 'r'?
                        <div className="selection selected" onClick={() => setSchoolStudents('r')}>R</div>:
                        <div className="selection" onClick={() => setSchoolStudents('r')}>R</div>
                    }
                    {alphabetSelector == 's'?
                        <div className="selection selected" onClick={() => setSchoolStudents('s')}>S</div>:
                        <div className="selection" onClick={() => setSchoolStudents('s')}>S</div>
                    }
                    {alphabetSelector == 't'?
                        <div className="selection selected" onClick={() => setSchoolStudents('t')}>T</div>:
                        <div className="selection" onClick={() => setSchoolStudents('t')}>T</div>
                    }
                    {alphabetSelector == 'u'?
                        <div className="selection selected" onClick={() => setSchoolStudents('u')}>U</div>:
                        <div className="selection" onClick={() => setSchoolStudents('u')}>U</div>
                    }
                    {alphabetSelector == 'v'?
                        <div className="selection selected" onClick={() => setSchoolStudents('v')}>V</div>:
                        <div className="selection" onClick={() => setSchoolStudents('v')}>V</div>
                    }
                    {alphabetSelector == 'w'?
                        <div className="selection selected" onClick={() => setSchoolStudents('w')}>W</div>:
                        <div className="selection" onClick={() => setSchoolStudents('w')}>W</div>
                    }
                    {alphabetSelector == 'x'?
                        <div className="selection selected" onClick={() => setSchoolStudents('x')}>X</div>:
                        <div className="selection" onClick={() => setSchoolStudents('x')}>X</div>
                    }
                    {alphabetSelector == 'y'?
                        <div className="selection selected" onClick={() => setSchoolStudents('y')}>Y</div>:
                        <div className="selection" onClick={() => setSchoolStudents('y')}>Y</div>
                    }
                    {alphabetSelector == 'z'?
                        <div className="selection selected" onClick={() => setSchoolStudents('z')}>Z</div>:
                        <div className="selection" onClick={() => setSchoolStudents('z')}>Z</div>
                    }
                </div>
                }
                </div>
                
            <div className="list-container">
            
                <div className="card-list">
                    <div className="student sort">
                    <div className={getRosterSyncClass()} disabled={rosterSyncing} styles={{fontSize: '8px'}} onClick={() => syncCourseRoster(courseInfo.course_id)}><FontAwesomeIcon icon={faSync}></FontAwesomeIcon><br/>Sync</div>
                    <div className="page-csv-download"><CSVLink filename={new Date().toDateString() + "-" + courseInfo.name  + "-students-report.csv"} data={csvRepData}><FontAwesomeIcon icon={faFileCsv} data-tip={"Download contents of this page in CSV format"} style={{ color: "black" }}></FontAwesomeIcon></CSVLink></div>
                        <div className="person">
                            <div className="profile-pic">

                            </div>
                            <div className="name" onClick={(e) => sortClick("name")}>
                                Student
                        <FontAwesomeIcon icon={faArrowDown} />
                            </div>
                        </div>
                        <div className="missing-assignments" onClick={(e) => sortClick("missing-assignments")}>
                            Missing Assignments
                        <FontAwesomeIcon icon={faArrowDown} />
                        </div>
                        <div className="participation-container" onClick={(e) => sortClick("risk")}>
                            <div className="bar-title">Risk Level<FontAwesomeIcon icon={faArrowDown} /></div>

                            <div className="participation-bar">
                            </div>
                        </div>
                        <div className="trend-container" onClick={(e) => sortClick("trend")}>
                            Trend
                        <FontAwesomeIcon icon={faArrowDown} />
                        </div>
                    </div>
                    
                    <FlipMove>
                    
                    {window.location.href.includes("school") && searchTerm.length > 2?
                    students:
                    dynamicSearch()}
                    </FlipMove>
                    
                </div>
            </div>
        </Fragment>
    )
}

export default Students;
