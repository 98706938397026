import React, { useState } from "react";
import Instructors from "../components/Instructors"
import Courses from "../components/Courses";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { useHistory } from "react-router-dom";

const AdminHome = (props) => {
    const history = useHistory();
    var selection = '1';
    var isShowing = true;

    //console.log(props);

    useHistory();

    try
    {
        selection=JSON.stringify(props.history.location.state.params)[1];
        //console.log("Props: " + JSON.stringify(props));
        if(selection === '2')
        {
            //console.log("Setting showing to false");
            isShowing=false;
        }
    }
    catch(err)
    {
        console.log("No props");
    }
    const [radioValue, setRadioValue] = useState(selection);
    const [showing, setShowing] = useState(isShowing);
    
    const toggleInstructorCourses = (e) =>
    {
        console.log("E: " + e.currentTarget.value);
        if(e.currentTarget.value == 3)
        {
            let path = '/TeacherHome/school/'; 
            history.push(path);
        }
        setRadioValue(e.currentTarget.value);
        setShowing(!showing);
        //console.log("Showing: " + showing);
        try{
            props.history.location.state.search = '';
        }
        catch(err)
        {
            console.log(err);
        }

    }

    const radios = [
        { name: 'Instructors', value: '1', disabled: false },
        { name: 'Courses', value: '2', disabled: false },
        { name: 'Students', value: '3', disabled: false}
    ];


    return (
        <div className="ButtonGroup">
            <ButtonGroup toggle>
                {radios.map((radio, idx) => (
                    <ToggleButton
                        key={idx}
                        type="radio"
                        variant="secondary"
                        name="radio"
                        value={radio.value}
                        checked={radioValue === radio.value}
                        onChange={(e) => toggleInstructorCourses(e)}
                        disabled={radio.disabled}
                    >
                        {radio.name}
                    </ToggleButton>
                ))}
            </ButtonGroup>
            {showing
             ? <Instructors></Instructors>
             : <Courses>{props}</Courses>
            }
            
        </div>
    );
};

export default AdminHome;