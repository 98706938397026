import React, { useState } from "react";
import Students from "../components/Students";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { useHistory } from 'react-router-dom';

const TeacherHome = (props) =>{
    const [radioValue, setRadioValue] = useState('3');
    const [showing, setShowing] = useState(true);
    const history = useHistory();

    const getCookieRole = () => {
        let cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)role\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        return cookieValue;
    }

    const toggleInstructorCourses = (e) =>
    {
        setRadioValue(e.currentTarget.value);
        setShowing(!showing);
        console.log("Showing: " + showing);
        history.push("/AdminHome", {params: e.currentTarget.value});
    }

    const radios = [
        { name: 'Instructors', value: '1', active: false },
        { name: 'Courses', value: '2', active: false },
        { name: 'Students', value: '3', active: true}
    ];
    return(
        <div>
            <ButtonGroup toggle>
                {radios.map((radio, idx) => (
                    <ToggleButton
                        key={idx}
                        type="radio"
                        variant="secondary"
                        name="radio"
                        value={radio.value}
                        checked={radioValue === radio.value}
                        onChange={(e) => toggleInstructorCourses(e)}
                        disabled={getCookieRole().toLowerCase().includes("instructor")?true:false}
                        
                    >
                        {radio.name}
                    </ToggleButton>
                ))}
            </ButtonGroup>
            <Students props={props}></Students>
        </div>
    );
};

export default TeacherHome;