import React, { Fragment, useState, useEffect } from "react";
import StudentLog from "../components/StudentLog";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { useHistory } from "react-router-dom";

const StudentView = (props) => {
    
    //console.log(props);
    const studentID = parseInt(props.match.params.studentID);
    const courseID = parseInt(props.match.params.courseID);

    const [radioValue, setRadioValue] = useState('3');
    const [courseInfo, setCourseInfo] = useState({});
    const [studentInfo, setStudentInfo] = useState({});

    const history = useHistory();

    const radios = [
        { name: 'Instructors', value: '1', active: false },
        { name: 'Courses', value: '2', active: false },
        { name: 'Students', value: '3', active: true}
    ];

    const getCookieRole = () => {
        let cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)role\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        return cookieValue;
    }

    const isStudent = () =>
    {
        if(getCookieRole().toLowerCase().includes("administrator") || getCookieRole().toLowerCase().includes("instructor"))
            return false;
        return true;
    }

    const toggleInstructorCourses = (e) =>
    {
        console.log("Radio clickd: " + e.currentTarget.value);
        setRadioValue(e.currentTarget.value);
        history.push("/AdminHome", {params: e.currentTarget.value});
    }

    async function getCourseInfo() {
        try {
            
            var response_string = "";
            response_string = "/api/course/info?id=" + courseID;
            //console.log(JSON.stringify(props.match.params.courseID));
            const response = await fetch(response_string);
            const jsonData = await response.json();
            //console.log("Course Info: " + JSON.stringify(jsonData));
            setCourseInfo(jsonData.info);
        }
        catch (err) {
            console.log(err);
        }
    }

    async function getStudentInfo() {
        try {
            
            var response_string = "";
            response_string = "/api/student/info?courseID=" + courseID + "&studentID=" + studentID;
            //console.log(JSON.stringify(props.match.params.courseID));
            const response = await fetch(response_string);
            const jsonData = await response.json();
            //console.log("Student Info recieved: " + JSON.stringify(jsonData));
            setStudentInfo(jsonData);
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getCourseInfo();
        getStudentInfo();
    }, []);

    return(
            <div className="student-view">
            {!isStudent()?
            <ButtonGroup toggle>
                {radios.map((radio, idx) => (
                    <ToggleButton
                        key={idx}
                        type="radio"
                        variant="secondary"
                        name="radio"
                        value={radio.value}
                        checked={radioValue === radio.value}
                        onChange={(e) => toggleInstructorCourses(e)}
                        disabled={getCookieRole().toLowerCase().includes("instructor") || getCookieRole().toLowerCase().includes("learner") || getCookieRole().toLowerCase().includes("student")?true:false?true:false?true:false}
                    >
                        {radio.name}
                    </ToggleButton>
                ))}
            </ButtonGroup>
            :<div></div>}
            {isStudent()?<div></div>:<div className="course-title"> > <div className="course-title-button" onClick={(e) => history.push("/TeacherHome/"+courseID)}>{courseInfo.name}</div> > {studentInfo.name}</div>}
            {JSON.stringify(studentInfo) == JSON.stringify({})?<div>LOADING...</div>:<StudentLog props={{studentInfo, courseID:courseID}}></StudentLog>}
            {/* {JSON.stringify({studentInfo}) == JSON.stringify({})?<div>LOADING...</div>:
            // <StudentLog props={{studentInfo: studentInfo}}> </StudentLog>
            } */}
            </div>
    )
}

export default StudentView;