
import React, { Fragment, useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowDown, faArrowUp, faCog, faThumbtack, faCheckSquare, faTrash, faEdit, faSave, faUndoAlt, faDownload, faUpload, faFileCsv, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import Badge from 'react-bootstrap/Badge';

const UpdateEntryModal = (props) =>{
    console.log("UpdateEntryModal props: " + JSON.stringify(props.props));
    const studentName = props.props.selectedEntry.student.studentName;
    const studentID = props.props.selectedEntry.student.studentID;
    const entry = props.props.selectedEntry.entry;
    const [show, setShow] = useState(props.props.showUpdate);
    const [entryText, setEntryText] = useState(entry.entry_text);
    const [entrySubject, setEntrySubject] = useState(entry.entry_subject);
    const [entryType, setEntryType] = useState(entry.entry_type);
    const [isContact, setIsContact] = useState(entry.contact);
    const [isPinned, setIsPinned] = useState(entry.is_pinned);
    const [contactDate, setContactDate] = useState(new Date(entry.contact_date).toISOString().slice(0, 10));
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [wasSubmitted, setWasSubmitted] = useState(false);
    const [submittedAtDate, setSubmittedAtDate] = useState("");
    const [selectedStudents, setSelectedStudents] = useState([{studentName: studentName, studentID: studentID}]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [showTagDropdown, setShowTagDropdown] = useState(false);
    const [isSelectingTags, setIsSelectingTags] = useState(false);
    const [tags, setTags] = useState([]);

    const handleClose = () => {
        console.log("Handleing close");
        setEntryText("");
        setEntryType("internal");
        setIsContact(false);
        setIsPinned(false);
        setContactDate(new Date().toISOString().slice(0, 10));
        setWasSubmitted(false);
        setSubmittedAtDate("");
        props.inputRef(false);
        setShow(false);
        //props.reRenderRef;
    }
    const handleShow = () => {
        setEntryType("internal");
        setShow(true);
    }

    const setContactToggle = (e) => {
        setIsContact(e.target.checked);
        console.log(isContact);
    }

    const handlePinned = (e) => {
        setIsPinned(!isPinned);
    }

    const handleContactDate = (e) => {
        setContactDate(e.target.value);
        console.log("Set contact date to: " + e.target.value);
    }

    const editEntryText = (e) => {
        setEntryText(e.target.value);
    }

    const editEntrySubject = (e) =>{
        setEntrySubject(e.target.value);
    }

    const editEntryType = (e) => {
        console.log("Editing entry type: " + JSON.stringify(e.currentTarget.value));
        setEntryType(e.currentTarget.value);
    }

    function getDate() {
        var date = new Date(); //create date object 
        var min_date = date.toISOString().slice(0, 10);
        //console.log("Returning date: " + min_date);
        //setContactDate(min_date);
        return min_date;
    }

    const handleEntrySubmit = () => {
        
        var data = {
            subject: entrySubject,
            text: entryText,
            type: entryType.toLowerCase(),
            contact: isContact,
            contactDate: contactDate,
            pinned: isPinned,
            students: selectedStudents,
            id: entry.entry_id,
            tags: selectedTags
        }

        console.log("Updating Entry: ");
        console.log(JSON.stringify(data));
        if(data.text != "")
        {
            setIsSubmitting(true);
            updateEntry(data);
        }
    }

    function removeStudentDropdown(student, elem) {
        var copy = [...selectedStudents];
        for(var i = 0; i < copy.length; i++)
        {
            //console.log("Searching for student: " + JSON.stringify(student));
            if(selectedStudents[i].studentID === student.studentID)
            {
                //console.log("Found student at index: " + i);
                var spliced = selectedStudents.splice(i, 1);
                //elem.classList.remove("selected");
                setSelectedStudents([...selectedStudents]);
                //console.log("Removed student: " + JSON.stringify(spliced));
                
                return true;
            }
        }
        //console.log("I made it far");
        return false;
    }

    async function updateEntry(data) {
        
        try {
            var request = "/api/students/logentry/update";
            const rawResponse = await fetch(request, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(response => response.json())
            .then(data => {
                if (data.success)
                    setWasSubmitted(true);
                    setSubmittedAtDate(data.submittedAt);
            })
        .then(setIsSubmitting(false))
        //Check for success or error

        }
        catch(err){
            console.log("Error on submit: " + err);
            setIsSubmitting(false);
            setWasSubmitted(false);
        }
    }

    function removeTagDropdown(tag, e) {
        var copy = [...selectedTags];
        for(var i = 0; i < copy.length; i++)
        {
            //console.log("Searching for student: " + JSON.stringify(student));
            if(copy[i].tagID === tag.id)
            {
                if(tag.id == 1)
                {
                    console.log("Contact tag active");
                    setIsContact(false);
                }
                //console.log("Found student at index: " + i);
                var spliced = selectedTags.splice(i, 1);
                //elem.classList.remove("selected");
                setSelectedTags([...selectedTags]);
                //console.log("Removed student: " + JSON.stringify(spliced));
            }
        }
        setIsSelectingTags(true);
        setShowTagDropdown(true);
        e.stopPropagation();
        //console.log("I made it far");
        return false;
    }

    const addToSelectedTags = (tag, e, shouldClose) =>{
        var arr = [...selectedTags];
        arr.push({tagID: tag.id, tagName: tag.tag});
        
        if(tag.id == 1)
        {
            console.log("Contact tag active");
            setIsContact(true);
        }
        //selectedToTop();
        setSelectedTags(arr);
        if(!shouldClose)
        {
            setIsSelectingTags(true);
            setShowTagDropdown(true);
            e.stopPropagation();
        }
        else
        {
            setIsSelectingTags(false);
            setShowTagDropdown(false);
        }
        
        //console.log("Adding selected");
    }

    async function getTagsFor(entryID)
    {
        try {
            var request = "/api/logentry/tags/view";
            const rawResponse = await fetch(request, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({entryID: entryID})
            });
            const tags = await rawResponse.json();

            //console.log(JSON.stringify(tags.rows));

            return tags.rows;
        }
        catch(err)
        {
            console.log(err);
        }
    }

    async function setInitialTags(entryID)
    {  
        var first = [];
       const initTags = await getTagsFor(entryID);
       for(var i = 0; i < initTags.length; i++)
       {
            first.push({tagID: initTags[i].id, tagName: initTags[i].tag});
       } 
       setSelectedTags([...first]);
    }

    async function getTags(){
        try {
            //console.log(props.props.match.params.courseID);
            var response_string = "/api/logentry/tags/usercontrol";
            //console.log(JSON.stringify(props.match.params.courseID));
            const response = await fetch(response_string);
            const jsonData = await response.json();
            //console.log("STUDENTS: " + JSON.stringify(jsonData));
            setTags(jsonData);
        }
        catch(err)
        {
            console.log("Error getting students in NewEntryModal.");
        }
    }

    const isTagSelected = (tag) =>{
        if(selectedTags.some(e=>e.tagID == tag.id)){
            //console.log("Student is selected");
            return true;
        }
    }

    useEffect(() => {
        
        //handleShow();
        
        getTags();
        setInitialTags(entry.entry_id);
    }, []);

    

    return(
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
            <Modal.Title className="modal-title">Update Log Entry </Modal.Title>
            <DropdownButton disabled={true} id="dropdown-basic-button" title={selectedStudents[0] ? selectedStudents[0].studentName + " + " + (selectedStudents.length - 1) + " others" : "NONE"}>
                {
                    selectedStudents.map(student => (
                        <Dropdown.Item key={student.studentID} className="student-dropdown">{student.studentName}<div className="dropdown-selection" onClick={function () { removeStudentDropdown(student) }}>x</div></Dropdown.Item>
                    ))
                }
            </DropdownButton>
        </Modal.Header>
        <Modal.Body>
            
            <div className="log-entry-head-wrap">
                <div className="entry-type">
                <div className="type-selection">
                        <div className="selection"><label>Internal</label><input name="type" type="radio" value="Internal" checked={entryType.toLowerCase()==="internal"?true:false} onChange={editEntryType} disabled={wasSubmitted?true:false}/></div>
                        <div className="selection"><label>Public</label><input name="type" type="radio" value="Public" checked={entryType.toLowerCase()==="public"?true:false} onChange={editEntryType} disabled={wasSubmitted?true:false}/></div>
                    </div>
                
                    
                    
                    <div className="tag-selector">
                        <DropdownButton title={'Tags'}>
                            {
                                tags.map(tag => (
                                    <Dropdown.Item key={tag.id} onClick={isTagSelected(tag)? function (e){ removeTagDropdown(tag, e, true)}: function (e) {addToSelectedTags(tag, e, true)}} style={isTagSelected(tag)&&{backgroundColor: "#007bff", color: "white", borderTopStyle: "solid", borderTopColor: "white"}}>{tag.tag}
                                    {isTagSelected(tag)?
                                    <div className="dropdown-selection" onClick={function (e) { removeTagDropdown(tag, e, false) }}><FontAwesomeIcon icon={faMinus}></FontAwesomeIcon></div>
                                    :
                                    <div className="dropdown-selection" onClick={function (e) { addToSelectedTags(tag, e, false)}}><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon></div>
                                    }
                                    </Dropdown.Item>
                                ))
                            }
                        </DropdownButton>
            </div>
                    <div className={"contact-enabled"}>
                    <div className="contact-wrapper">
                        <input id="contact-toggle" onChange={setContactToggle} type="checkbox" checked={isContact} disabled={wasSubmitted?true:false}></input>
                        <label style={isContact ? { fontWeight: "bold", opacity: "1" } : {}}>Two-way contact</label>
                    </div>
                    <input type="date" value={contactDate === "" ? getDate() : contactDate}  onChange={handleContactDate} disabled={wasSubmitted}/>
                    
                </div>
                

                    <div className="entry-pin-toggle" onClick={!wasSubmitted?handlePinned:undefined} >
                        <div className={isPinned ? "pin-notify pin-enabled" : "pin-notify"} disabled={wasSubmitted?true:false}>pinned</div>
                        <FontAwesomeIcon icon={faThumbtack} />
                    </div>
                </div>
                
            </div>
            <div className="tag-display-creation">
                {
                    selectedTags.map(tag => (
                        <Badge style={{backgroundColor: "#d6d6d6", margin: "1px"}}>{tag.tagName}</Badge>
                    ))
                }
            </div>
            <input type="text" onChange={editEntrySubject} maxLength={50} value={entrySubject} placeholder="Subject" disabled={wasSubmitted ? true:false}/>
            <textarea onChange={editEntryText} maxLength={6000} value={entryText} placeholder="Type here..." disabled={wasSubmitted ? true:false}>

            </textarea>

        </Modal.Body>
        {wasSubmitted?<center><div style={{fontSize:"12px"}}>Submitted at: {submittedAtDate}</div></center>:""}
        <Modal.Footer>
            {!wasSubmitted ? 
            <Button variant="primary" onClick={handleEntrySubmit} disabled={selectedStudents.length<1 || entryText=="" || entrySubject==""?true:false}>
                {!isSubmitting ? "Submit" : <FontAwesomeIcon className="cog fa-spin" icon={faCog}/>}
            </Button> :
            <Button variant="success" disabled={true}>
            Entry Updated! <FontAwesomeIcon className="cog" icon={faCheckSquare}/>
            </Button>
            }
        </Modal.Footer>
    </Modal>
    )
}

export default UpdateEntryModal;